import { Component, Input, OnInit } from "@angular/core";
import {
  ColumnDef,
  FormattedTransaction,
  Transaction
} from "../transactions/transactions.component";

@Component({
  selector: "app-transactions-table",
  templateUrl: "./transactions-table.component.html",
  styleUrls: ["./transactions-table.component.scss"]
})
export class TransactionsTableComponent implements OnInit {
  @Input() txnType: "LEASE" | "SALE";
  @Input() transactions: FormattedTransaction[] = [];
  @Input() columnDefs: ColumnDef[] = [];

  constructor() {}

  ngOnInit(): void {}
}
