import { Component, Input, OnInit } from "@angular/core";
import { url } from "inspector";
import { environment } from "../../../../environments/environment";
import { ResponsiveSlideHelper } from "../../../core/_helpers";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";

@Component({
  selector: "app-industry",
  templateUrl: "./industry-leader.component.html",
  styleUrls: ["./industry-leader.component.scss"]
})
export class IndustryLeaderComponent implements OnInit {
  @Input() resource = false;
  imageCarousel = [];
  imageResource = [];
  currentCode: string;
  slidesConfig: any = ResponsiveSlideHelper.DEFAULT_SLIDE_CONFIG;
  titleResource = "";

  constructor(private siilaTranslationSrv: SiilaTranslationService) {
    this.currentCode = environment.platformId;
    this.titleResource = this.siilaTranslationSrv.getTranslationFromJSONFiles(
      `home.${this.currentCode}.pillars.sub-title-resource`
    );

    const imgSEOPath = "imgSEO.industryLeaders.";
    this.imageCarousel = [
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}capright`
        ),
        logo: `assets/img/home-list/logo-5.png`,
        url:
          this.currentCode == "br"
            ? "https://www.capright.com/"
            : "https://www.capright.com/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}colliers`
        ),
        logo: `assets/img/home-list/Colliers - mx-br - logo.png`,
        url:
          this.currentCode == "br"
            ? "https://www.colliers.com/"
            : "https://www.colliers.com/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}bbva`
        ),
        logo: `assets/img/home-list/bbva - mx-br - logo.png`,
        url:
          this.currentCode == "br"
            ? "https://www.bbva.com/"
            : "https://www.bbva.com/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}deloitte`
        ),
        logo: `assets/img/home-list/delloite-BR-MX.png`,
        url:
          this.currentCode == "br"
            ? "https://www.deloitte.com/"
            : "https://www.deloitte.com/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}mercadolibre`
        ),
        logo:
          this.currentCode == "br"
            ? "assets/img/home-list/Mercado-Livre-BR.png"
            : `assets/img/home-list/Mercado-Libre-MX.png`,
        url:
          this.currentCode == "br"
            ? " https://www.mercadolivre.com.br/"
            : "https://www.mercadolibre.com.mx/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}regus`
        ),
        logo: "assets/img/home-list/regus-logo-BR.png",
        url: "https://www.regus.com/"
      } /* ,
      {
        logo: `assets/img/home-list/tishman-speyer-logo -BR-MX.png`,
        url:
          this.currentCode == "br"
            ? "https://tishmanspeyer.com.br"
            : "https://capright.com"
      } */
    ];

    this.imageResource = [
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}valor`
        ),
        logo: `assets/img/resource-list/Valor Economico.jpg`,
        url: "https://valor.globo.com/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}bloomberg`
        ),
        logo: `assets/img/resource-list/Bloomberg Linea.jpg`,
        url: "https://www.bloomberglinea.com/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}elEconomista`
        ),
        logo: `assets/img/resource-list/El Economista.jpg`,
        url: "https://www.eleconomista.com.mx/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}forbes`
        ),
        logo: `assets/img/resource-list/Forbes.jpg`,
        url: "https://www.forbes.com/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}cnn`
        ),
        logo: `assets/img/resource-list/CNN.jpg`,
        url: "https://www.cnn.com/"
      },
      {
        title: this.siilaTranslationSrv.getTranslationFromJSONFiles(
          `${imgSEOPath}oglobo`
        ),
        logo: `assets/img/resource-list/O_Globo.png`,
        url: "https://oglobo.globo.com/"
      }
    ];
  }

  ngOnInit(): void {}

  openIndustry(url: string) {
    window.open(url, "_blank");
  }
}
