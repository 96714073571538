import { Injectable } from "@angular/core";
import { ImageSEO } from "../models/image-seo.model";
import { SiilaTranslationService } from "./siila-translation.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class StaticImagesSEOService {
  currentCode: string;

  constructor(private ts: SiilaTranslationService) {
    this.currentCode = environment.platformId;
  }

  getSiilaBrandSEOInfo(): ImageSEO {
    const titleKey = "siilabrand";
    const staticImg = "assets/img/SiiLA_SVG.svg";
    let result: ImageSEO = this.findTitleTranslation(titleKey, staticImg);
    return result;
  }

  getMarketAnalyticsRealIntelligenceSEO(): ImageSEO {
    const titleKey = "marketAnalyticsRealIntelligence";
    const staticImg = this.getImgBannerMarket();
    let result: ImageSEO = this.findTitleTranslation(titleKey, staticImg);
    return result;
  }

  getMultifamilySEO(): ImageSEO {
    const titleKey = "multifamilyText";
    const staticImg = this.getImgBannerMarket();
    let result: ImageSEO = this.findTitleTranslation(titleKey, staticImg);
    return result;
  }

  getAcademyImgSEO(): ImageSEO {
    const titleKey = "academyImg";
    const staticImg = this.getImgBannerAcademy();
    let result: ImageSEO = this.findTitleTranslation(titleKey, staticImg);
    return result;
  }

  getSpotTransformingExperienceSEO(): ImageSEO {
    const titleKey = "spotTransformingExperience";
    const staticImg = this.getSpotTransformingExperienceImg();
    let result: ImageSEO = this.findTitleTranslation(titleKey, staticImg);
    return result;
  }

  getCommercialestatenewslatam(): ImageSEO {
    const titleKey = "commercialestatenewslatam";
    const staticImg = this.imgNewMobile();
    let result: ImageSEO = this.findTitleTranslation(titleKey, staticImg);
    return result;
  }

  getLinkedinSEO(): ImageSEO {
    const titleKey = "socialmedia.linkedin";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  getTikTokSEO(): ImageSEO {
    const titleKey = "socialmedia.tiktok";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  getYoutubeSEO(): ImageSEO {
    const titleKey = "socialmedia.youtube";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  getFacebookSEO(): ImageSEO {
    const titleKey = "socialmedia.facebook";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  getInstagramSEO(): ImageSEO {
    const titleKey = "socialmedia.instagram";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  getTwitterSEO(): ImageSEO {
    const titleKey = "socialmedia.twitter";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  getShareWhatsappSEO(): ImageSEO {
    const titleKey = "sharesocial.whatsapp";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  getShareEmailSEO(): ImageSEO {
    const titleKey = "sharesocial.email";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  getShareFacebookSEO(): ImageSEO {
    const titleKey = "sharesocial.facebook";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  getShareLinkedinSEO(): ImageSEO {
    const titleKey = "sharesocial.linkedin";
    let result: ImageSEO = this.findTitleTranslation(titleKey);
    return result;
  }

  private getImgBannerMarket() {
    if (
      this.currentCode == "mx" ||
      this.currentCode == "latam" ||
      this.currentCode == "co"
    ) {
      return this.ts.getCurrentLanguage() == "en"
        ? "../../../../assets/img/Frame 574.jpg"
        : "../../../../assets/img/BANNER MA - RESOURCE.png";
    } else {
      return this.ts.getCurrentLanguage() == "en"
        ? "../../../../assets/img/Multifamily-Banner-EN.png"
        : "../../../../assets/img/Multifamily-Banner-PT.png";
    }
  }

  private getImgBannerAcademy() {
    if (
      this.currentCode == "mx" ||
      this.currentCode == "latam" ||
      this.currentCode == "co"
    ) {
      return this.ts.getCurrentLanguage() == "en"
        ? "../../../../assets/img/EN - BANNER ACADEMY ADVANCED 2024.png"
        : "../../../../assets/img/PT - BANNER ACADEMY ADVANCED 2024.png";
    } else {
      return this.ts.getCurrentLanguage() == "en"
        ? "../../../../assets/img/EN - BANNER ACADEMY ADVANCED 2024.png"
        : "../../../../assets/img/PT - BANNER ACADEMY ADVANCED 2024.png";
    }
  }

  private getSpotTransformingExperienceImg() {
    if (
      this.currentCode == "mx" ||
      this.currentCode == "latam" ||
      this.currentCode == "co"
    ) {
      return this.ts.getCurrentLanguage() == "en"
        ? "../../../../assets/img/MX EN VERSION.png"
        : "../../../../assets/img/SP VERSION.png";
    } else {
      return this.ts.getCurrentLanguage() == "en"
        ? "../../../../assets/img/Card.jpg"
        : "../../../../assets/img/PT VERSION.png";
    }
  }

  imgNewMobile() {
    let img = "../../../../assets/img/products-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.ts.getCurrentLanguage() == "en") {
        img = img + "IPHONE14_EN.png";
      } else {
        img = img + "IPHONE14_ES.png";
      }
    } else {
      if (this.ts.getCurrentLanguage() == "en") {
        img = img + "IPHONE14_EN.png";
      } else {
        img = img + "IPHONE14_PT.png";
      }
    }

    return img;
  }

  private findTitleTranslation(titleKey: string, staticImg?: string) {
    const title = this.ts.getTranslationFromJSONFiles(
      `imgSEO.${titleKey}.title`
    );

    let result: ImageSEO = {
      src: staticImg,
      title: title,
      alt: title
    };
    return result;
  }
}
