<section class="analytic-container">
  <div class=" border-card-markets mat-elevation-z15">
    <div class="main-card">
      <div class="analytic-info">
        <h1
          class="title-gnrl uppercase"
          translate="analytics.{{ currentCode }}.analytic-list.title"
        ></h1>
        <h2
          class="feature-tittle"
          translate="analytics.{{ currentCode }}.analytic-list.sub-title"
        ></h2>
        <p
          class="feature-sub-tittle"
          translate="analytics.{{ currentCode }}.analytic-list.description"
        ></p>
        <div class="button">
          <!--  <button mat-flat-button class="btn-info">
            {{ "more" | translate }}
          </button> -->
          <button
            (click)="openContactDialog(action.requestDemo)"
            mat-flat-button
            color="accent"
            class="btn-demo"
          >
            {{ "request" | translate }}
          </button>
        </div>
      </div>
      <div class="analytic-img">
        <img
          src="../../../../../assets/img/analytics-list/market-analytics-item2-mockupMA.png"
          [alt]="marketAnalyticsAltTitle"
          [title]="marketAnalyticsAltTitle"
        />
      </div>
    </div>
  </div>

  <div class="border-card-white mat-elevation-z15">
    <div class="market-dna-info">
      <h1
        class="title-gnrl siila-blue-color uppercase"
        translate="analytics.{{ currentCode }}.analytic-list.why"
      ></h1>
      <h2
        class="feature-title siila-menu-color"
        translate="analytics.{{ currentCode }}.analytic-list.features"
      ></h2>
    </div>
    <div class="main-card">
      <div class="market-info">
        <div class="check-list-analytic">
          <div class="check-market" *ngFor="let check of checkList">
            <img class="icon-check" [src]="check.icon" />
            <div>
              <span
                class="feature-product siila-menu-color"
                [translate]="check.title"
              ></span>
              <br /><span
                class="feature-product-info siila-grey"
                [translate]="check.desc"
              ></span>
            </div>
          </div>
          <div class="border-line-market"></div>
          <p
            class="feature-sub-title siila-grey"
            translate="analytics.{{ currentCode }}.analytic-list.text"
          ></p>
          <div>
            <button
              (click)="openContactDialog(action.subscribe)"
              mat-flat-button
              color="accent"
              class="btn-more uppercase"
            >
              {{ "subscribe" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="market-img">
        <img [alt]="mapicalt" [title]="mapicalt" [src]="imgAnalytics()" />
      </div>
    </div>
  </div>
  <div
    class=" border-card-add mat-elevation-z15"
    *ngIf="currentCode != 'latam'"
  >
    <div class="add-info">
      <h1
        class="title-gnrl uppercase"
        translate="analytics.{{ currentCode }}.analytic-list.title"
      ></h1>
      <h2
        class="feature-tittle"
        translate="analytics.{{ currentCode }}.product-add.sub-title"
      ></h2>
    </div>
    <div class="add-ons">
      <div class="card-state">
        <img
          [alt]="propertyIcon"
          [title]="propertyIcon"
          src="../../../../../assets/img/analytics-list/property.svg"
        />
        <p class="feature-tittle">
          {{ (currentCode == "co" ? 80 : 100) | numberSuffix }}%
        </p>
        <h3 class="state-info">
          {{ translateMarketInfo + ".sale" | translate }}
          <div
            [ngStyle]="{ 'margin-top': browserLang == 'en' ? '32px' : '' }"
            class="orange-border"
          ></div>
        </h3>
      </div>

      <div class="card-state">
        <img
          [alt]="officeIcon"
          [title]="officeIcon"
          src="../../../../../assets/img/analytics-list/office.svg"
        />
        <p class="feature-tittle">
          +{{ (currentCode == "co" ? 2500 : 8000) | numberSuffix }}
        </p>
        <h3 class="state-info">
          {{ translateMarketInfo + ".property" | translate }}
          <div class="orange-border"></div>
        </h3>
      </div>

      <div class="card-state">
        <img
          [alt]="moneyIcon"
          [title]="moneyIcon"
          src="../../../../../assets/img/analytics-list/money.svg"
        />
        <p class="feature-tittle">
          +{{ (currentCode == "co" ? 3500 : 5500) | numberSuffix }}
        </p>
        <h3 class="state-info">
          {{ translateMarketInfo + ".industrial" | translate }}
          <div class="orange-border"></div>
        </h3>
      </div>

      <div class="card-state">
        <img
          [alt]="accountIcon"
          [title]="accountIcon"
          src="../../../../../assets/img/analytics-list/account.svg"
        />
        <p class="feature-tittle">
          +{{ (currentCode == "co" ? 7000 : 55000) | numberSuffix }}
        </p>
        <h3 class="state-info">
          {{ translateMarketInfo + ".data" | translate }}
          <div style="margin-top: 32px;" class="orange-border"></div>
        </h3>
      </div>
    </div>
  </div>
  <div>
    <industry-news
      [tagId]="tagId"
      [isMarket]="true"
      id="news"
      #news
      class="industry-container mat-elevation-z15"
    ></industry-news>
  </div>
  <div
    class="home-card-comercial mat-elevation-z15"
    *ngIf="currentCode == 'latam'"
  >
    <div class="commercial-info">
      <h1
        class="commercial-title"
        translate="home.{{ currentCode }}.commercial.title"
      ></h1>
    </div>
    <div class="commercial-data">
      <div class="card-data-left">
        <p
          *ngIf="browserLang === 'en' || browserLang === 'es'"
          class="number-style"
        >
          +{{ 19000 | numberSuffix }}
        </p>
        <p *ngIf="browserLang === 'pt'" class="number-style">+{{ 19 }} mil</p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.industrial"
        ></h1>
      </div>
      <div class="card-data-middle">
        <p
          *ngIf="browserLang === 'en' || browserLang === 'es'"
          class="number-style"
        >
          +USD${{ 72000000000 | numberSuffix }}
        </p>
        <p *ngIf="browserLang === 'pt'" class="number-style">
          +USD$ {{ 720 }} bilhões
        </p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.sale"
        ></h1>
      </div>
      <div class="card-data-right">
        <p class="number-style">{{ 85 }}%</p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.latam"
        ></h1>
      </div>
      <div class="card-data-left">
        <p class="number-style">{{ 3 }}</p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.countries"
        ></h1>
      </div>
      <div class="card-data-middle">
        <p
          *ngIf="browserLang === 'en' || browserLang === 'es'"
          class="number-style"
        >
          {{ 180000000 | numberSuffix }} m²
        </p>
        <p *ngIf="browserLang === 'pt'" class="number-style">
          +{{ 180 }} milhões m²
        </p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.Analyzed"
        ></h1>
      </div>
      <div class="card-data-right">
        <p
          *ngIf="browserLang === 'en' || browserLang === 'es'"
          class="number-style"
        >
          +{{ 120000 | numberSuffix }}
        </p>
        <p *ngIf="browserLang === 'pt'" class="number-style">+{{ 120 }} mil</p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.Tenants"
        ></h1>
      </div>
    </div>
  </div>
  <div class=" border-card-white mat-elevation-z15" *ngIf="currentCode != 'co'">
    <div class="add-info">
      <h1
        class="title-gnrl siila-blue-color"
        translate="analytics.{{ currentCode }}.product-add.title"
      ></h1>
      <h2
        class="feature-tittle siila-menu-color"
        translate="analytics.{{ currentCode }}.product-add.text"
      ></h2>
    </div>
    <div id="tomadecisiones" class="market-state-card">
      <ng-container *ngFor="let card of marketCardList">
        <app-info-card
          (click)="openCard(card.id, card.title)"
          [icon]="card.src"
          [iconAltTitle]="card.iconAltTitle"
          [title]="card.title | langSelector"
          [text]="card.text | langSelector"
          [actionTxt]="card.actionText | langSelector"
        ></app-info-card>
      </ng-container>
    </div>
  </div>
  <div class=" border-card-white mat-elevation-z15">
    <div
      class="strategy-world-info"
      [ngClass]="{ 'strategy-world-info-pt': browserLang === 'pt' }"
    >
      <h1
        class="title-gnrl siila-blue-color"
        translate="analytics.{{ currentCode }}.strategy.title"
      ></h1>
      <h2
        class="feature-tittle siila-menu-color"
        translate="analytics.{{ currentCode }}.strategy.text"
      ></h2>
    </div>
    <div class="strategy-card">
      <div class="strategy-img">
        <img
          src="../../../../../assets/img/analytics-list/strategy-world.png"
          [alt]="strategyWorld"
          [title]="strategyWorld"
        />
      </div>
      <div class="strategy-info">
        <div class="check-list-analytic">
          <div
            style="display: flex;"
            class="check"
            *ngFor="let check of analyticCheck"
          >
            <img class="icon-check" [src]="check.icon" />
            <div>
              <span
                class="feature-product siila-menu-color"
                [translate]="check.title"
              ></span>
              <span
                class="feature-product-info siila-dark"
                [translate]="check.desc"
              ></span>
            </div>
          </div>
        </div>
        <button
          (click)="openContactDialog(action.moreInfo)"
          mat-flat-button
          color="accent"
          class="btn-more"
        >
          {{ "moreInfo" | translate }}
        </button>
      </div>
    </div>
  </div>
  <app-methodology></app-methodology>
  <app-solution-list></app-solution-list>
  <!--  <app-featured-resources></app-featured-resources> -->
  <app-exclusive-access></app-exclusive-access>
  <app-btn-up></app-btn-up>
</section>
