import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
  Renderer2
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { zip } from "rxjs";
import { environment } from "../../../../environments/environment";
import { NewsService } from "../../../core";
import { ProductService } from "../../../core/services/products.service";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { MoreNews } from "../../models/more-news.model";
import { NewsSearch } from "../../models/news-search.model";
import { NewsTopics } from "../../models/news-topics.model";
import { NewsDetail } from "../../models/news.model";
import { I18nService } from "../../services";
import { DOCUMENT, DatePipe } from "@angular/common";
import { MetaTagService } from "../../services/meta-tag.service";
import { StaticImagesSEOService } from "../../../core/services/static-images.service";
import { ImageSEO } from "../../../core/models/image-seo.model";
import { NewsNavigateService } from "../../services/news.navigate.service";
import { MatDialog } from "@angular/material/dialog";
import { InstallPromptComponent } from "../dialogs";
import { PWAService } from "../../services/pwa-service";

@Component({
  selector: "app-home-news",
  templateUrl: "./home-news.component.html",
  styleUrls: ["./home-news.component.scss"]
})
export class HomeNewsComponent implements OnInit, AfterViewInit {
  currentCode: string;
  isExpanded: boolean = false;
  isMobile = false;
  news: NewsSearch;
  mostRead: MoreNews[] = [];
  topics: NewsTopics[] = [];
  pageSize = 6;
  page = 0;
  total = 0;
  tagIdFilter = null;
  email: string = "";
  keyword = "";
  tagName: string = null;
  color;
  innerWidth: number;
  browserLang: any;
  marketAnalyticsSEO: ImageSEO;
  spotTransformingSEO: ImageSEO;
  siilaAcademyBannerSEO: ImageSEO;
  loadingNews: boolean = true;
  alreadyLoaded: boolean = false;
  show = true;
  deferredPrompt: any;
  showInstall = true;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (innerWidth > 900) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  constructor(
    private newsService: NewsService,
    private i18: I18nService,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private siilaTranslationService: SiilaTranslationService,
    private datePipe: DatePipe,
    private metaSrv: MetaTagService,
    private staticImgSrv: StaticImagesSEOService,
    private router: Router,
    private newsNavigate: NewsNavigateService,
    private matDialog: MatDialog,
    private pwaService: PWAService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.currentCode = environment.platformId;
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        console.log("resolucion", result);
        this.isMobile = result.matches;
      });
    if (this.currentCode == "latam") {
      this.browserLang = "latam";
    } else {
      this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    }
  }

  ngAfterViewInit(): void {
    const scroll = localStorage.getItem("scrollPosition");
    this.show = false;
    if (scroll) {
      const scrollPosition = Number(scroll);
      setTimeout(() => {
        localStorage.removeItem("scrollPosition");
        localStorage.removeItem("pageSize");
        window.scrollTo(0, scrollPosition);
      }, 2000);

      setTimeout(() => {
        this.show = true;
      }, 4000);
    } else {
      this.show = true;
    }
  }

  ngOnInit() {
    this.setIcons();
    setTimeout(() => {
      this.showInstall = false;
    }, 15000);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.location.reload();
        /* if (event.url && event.url.includes("/resource")) {
          window.location.reload();
        } */
      }
    });
    this.marketAnalyticsSEO = this.staticImgSrv.getMultifamilySEO();
    this.spotTransformingSEO = this.staticImgSrv.getSpotTransformingExperienceSEO();
    this.siilaAcademyBannerSEO = this.staticImgSrv.getAcademyImgSEO();
    this.route.params.subscribe(param => {
      if (param.id) {
        this.tagIdFilter = param.id;
        this.tagName = param.type;
        this.color = param.color;
      }
      this.metaSrv.setInsightsHomeTags();
      console.log("parametros", param);
      this.getNews();
      this.getMostNews();
      this.getTopics();
    });
  }

  getAltTxt(text: string) {
    return this.siilaTranslationService.getTranslation(text);
  }

  getNews() {
    this.loadingNews = true;
    console.log("filter", this.tagIdFilter, this.tagName);
    let regionOrigin = null;
    if (this.tagIdFilter == 20 && this.currentCode == "latam") {
      const countryBR = this.siilaTranslationService.getTranslationFromJSONFiles(
        "news.brasil"
      );
      const countryMX = this.siilaTranslationService.getTranslationFromJSONFiles(
        "news.mexico"
      );
      regionOrigin =
        this.tagName.toLowerCase() == countryBR.toLowerCase()
          ? 101
          : this.tagName.toLowerCase() == countryMX.toLowerCase()
          ? 102
          : null;
    }

    this.newsService
      .getNewsSearch(
        this.browserLang,
        this.page,
        localStorage.getItem("pageSize")
          ? Number(localStorage.getItem("pageSize"))
          : this.pageSize,
        null,
        this.tagIdFilter,
        regionOrigin
      )
      .subscribe((resp: NewsSearch) => {
        this.news = resp;
        this.loadingNews = false;
        this.alreadyLoaded = true;
        this.total = resp.totalElements;
      });
  }

  getMostNews() {
    this.newsService.getMostNews().subscribe(resp => {
      this.mostRead = resp as MoreNews[];
    });
  }

  getTopics() {
    this.newsService.getNewsTopics().subscribe(resp => {
      this.topics = resp as NewsTopics[];
    });
  }

  headerImageNews(item: string, size = false): string {
    const url = item.replace("***", size ? "1280w" : "640w");
    return `${environment.cloudfrontURL}${url}`;
  }

  seeMore() {
    this.pageSize = this.pageSize + 5;
    this.getNews();
  }

  openAcademy() {
    window.open(
      "https://mailchi.mp/siila/academy-advanced",
      this.isMobile ? "_self" : "_blank"
    );
  }

  openNews(id: number, title) {
    this.newsNavigate.openNews(
      id,
      title.urlTitle ? title.urlTitle : title.headline,
      this.isMobile,
      this.pageSize,
      this.isIOS(),
      this.browserLang
    );
  }

  openNewsSearch() {
    this.newsNavigate.openNewsSearch(this.isMobile, this.keyword);
  }

  validEmail() {
    var EMAIL_REGEX = /\S+@\S+\.\S+/;

    if (this.email.match(EMAIL_REGEX)) {
      return false;
    } else {
      return true;
    }
  }

  public sendEmail() {
    let message = this.i18.get("global.newsForm.messageEmail");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");
    this.productService.sendEmail(this.email).subscribe(
      () => {
        this.snackBar.open(message, action);
        this.email = "";
      },
      () => {
        this.snackBar.open(errorMsj, action);
      }
    );
  }

  getLanguage() {
    return this.siilaTranslationService.getCurrentLanguage();
  }

  goNewsTags(tag, color, regionOrigin?: number) {
    this.newsNavigate.goNewsTags(
      tag,
      color,
      this.getNationalStateName(tag, regionOrigin),
      this.isMobile
    );
  }

  formatDate(date, format) {
    return this.datePipe.transform(date, format);
  }

  isEnglishLang() {
    return this.i18.getCurrentLanguage() === "en";
  }

  openSpot() {
    if (this.isBR()) {
      var url = this.isEnglishLang()
        ? "https://siilaspot.com.br/en"
        : "https://siilaspot.com.br/pt-br";
      window.open(url, this.isMobile ? "_self" : "_blank");
    } else {
      window.open(
        "/SPOT" + `/lang/${this.i18.getCurrentLanguage()}`,
        this.isMobile ? "_self" : "_blank"
      );
    }
  }

  openProduct() {
    window.open(
      "/marketanalytics" + `/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openMultifamilyBanner() {
    let url = this.isEnglishLang()
      ? "https://mailchi.mp/siila.com.br/7gms21uue1"
      : "https://mailchi.mp/siila.com.br/multifamily";
    window.open(url, this.isMobile ? "_self" : "_blank");
  }

  goHome(latam = false) {
    this.newsNavigate.goHome(latam);
  }

  getStateTagName(contentTags: any) {
    const stateTag = contentTags.stateTags[0];
    return this.getNationalStateName(stateTag, contentTags.regionOrigin);
  }

  private getNationalStateName(stateTag: any, regionOrigin: number) {
    let stateName = null;
    if (stateTag.id == 20 && regionOrigin) {
      const country = regionOrigin == 102 ? "mexico" : "brasil";
      stateName = this.siilaTranslationService
        .getTranslationFromJSONFiles("news." + country)
        .toLowerCase();
    } else {
      stateName = this.siilaTranslationService.getTranslation(stateTag.name);
    }

    return stateName;
  }

  isIOS(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  validateTitle(title) {
    if (title.match(/´/)) {
      return title.replace(/´/g, "'");
    } else {
      return title;
    }
  }

  installApp() {
    if (this.isIOS()) {
      this.matDialog.open(InstallPromptComponent);
    } else {
      this.pwaService.promptPWAInstall();
    }
  }

  isStandAlone() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }

  isBR() {
    return this.currentCode && this.currentCode.toLowerCase() === "br";
  }

  closeInstall() {
    this.showInstall = false;
  }

  setIcons() {
    if (this.isIOS()) {
      const linkIcon = this.document.getElementById(
        "dynamic-192"
      ) as HTMLLinkElement | null;
      linkIcon.href = "assets/icons/android-chrome-192x192-i2.png";

      const linkIcon2 = this.document.getElementById(
        "dynamic-144"
      ) as HTMLLinkElement | null;
      linkIcon2.href = "assets/icons/android-chrome-144x144-i2.png";

      const linkIcon3 = this.document.getElementById(
        "dynamic-96"
      ) as HTMLLinkElement | null;
      linkIcon3.href = "assets/icons/android-chrome-96x96-i2.png";

      const linkIcon4 = this.document.getElementById(
        "dynamic-72"
      ) as HTMLLinkElement | null;
      linkIcon4.href = "assets/icons/android-chrome-72x72-i2.png";

      const linkIcon5 = this.document.getElementById(
        "dynamic-48"
      ) as HTMLLinkElement | null;
      linkIcon5.href = "assets/icons/favicon-48x48-i2.png";
    } else {
      const linkIcon = this.document.getElementById(
        "dynamic-192-a"
      ) as HTMLLinkElement | null;
      linkIcon.href = "assets/icons/android-chrome-192x192-i2.png";

      const linkIcon2 = this.document.getElementById(
        "dynamic-144-a"
      ) as HTMLLinkElement | null;
      linkIcon2.href = "assets/icons/android-chrome-144x144-i2.png";

      const linkIcon3 = this.document.getElementById(
        "dynamic-96-a"
      ) as HTMLLinkElement | null;
      linkIcon3.href = "assets/icons/android-chrome-96x96-i2.png";

      const linkIcon4 = this.document.getElementById(
        "dynamic-72-a"
      ) as HTMLLinkElement | null;
      linkIcon4.href = "assets/icons/android-chrome-72x72-i2.png";

      const linkIcon5 = this.document.getElementById(
        "dynamic-48-a"
      ) as HTMLLinkElement | null;
      linkIcon5.href = "assets/icons/favicon-48x48-i2.png";
    }
  }
}
