import { Pipe, PipeTransform } from "@angular/core";
import { ProductService } from "../../core/services/products.service";

@Pipe({
  name: "langSelector"
})
export class LangSelectorPipe implements PipeTransform {
  langSelector: string;
  constructor(private productService: ProductService) {
    this.langSelector = this.productService.getLangSelector();
  }
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == undefined) {
      return value;
    }
    let parsedObject = JSON.parse(value as string);

    let parsed = parsedObject[this.langSelector];

    if (!parsed) {
      parsed = Object.values(parsedObject)[0];
    }
    if (args[0]) {
      parsed = parsed
        .split(" ")
        .join("-")
        .toLowerCase();
    }
    return parsed;
  }
}
