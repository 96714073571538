import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PropertyService } from "../../services/property.services";
import { ActivatedRoute, Router } from "@angular/router";
import { SiilaIndexReportComponent } from "../static-pages";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { ImageGalleryComponent } from "../image-gallery/image-gallery.component";
import { BreakpointObserver } from "@angular/cdk/layout";
import { SendNotificationComponent } from "../send-notification/send-notification.component";
import { generate, zip } from "rxjs";
import { AccountService, NewsService } from "../../../core";
import { NewsSearch } from "../../models/news-search.model";
import { NewsNavigateService } from "../../services/news.navigate.service";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexTheme
} from "ng-apexcharts";
import { apexObj, apexObj2 } from "../../utils/params";
import { DatePipe } from "@angular/common";
import { I18nService } from "../../services";
import { MetaService } from "@ngx-meta/core";
import { FeaturedListingService } from "../../../core/services/featured-listing.service";
import { DeviceRegistrationService } from "../../../core/services/device-registration.service";
import { ProductService } from "../../../core/services/products.service";
import { ProductItem } from "../../../core/models/products.model";
import { color } from "html2canvas/dist/types/css/types/color";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonGenericService } from "../../services/common.service";
import * as e from "express";
import {
  PropertyFeaturesService,
  PropertyOptions
} from "../../services/property-features.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalNumberPipeSpot } from "../../pipes/number-pipe";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  stroke: any;
  dataLabels: any;
  fill: ApexFill;
  tooltip: ApexTooltip;
  theme: ApexTheme;
  colors: string[];
  events: any;
  responsive: any;
};

export type BuildingFeatureDetails = {
  featureName: string;
  featureDisplayName: string;
  featureSelectedOptions: string[];
};

export type PropertyFeaturesIds = {
  id: number;
  buildingId: number;
  featureId: number;
  selectedOptions: number[];
  active: boolean;
};

export type UnitMix = {
  floorPlan: string;
  numberOfUnits: number;
  avgArea: number;
  cqAvgMonthlyRent: number;
  monthlyrentsm: number;
  cqAvgMonthlyRentFurnished: number;
  monthlyrentsmf: number;
  detailsName: string;
};

@Component({
  selector: "app-detail-property",
  templateUrl: "./detail-property.component.html",
  styleUrls: ["./detail-property.component.scss"]
})
export class DetailPropertyComponent implements OnInit {
  dialogRef: any;
  id;
  isMobile: boolean = false;
  listingCards;
  property: any;
  photos: any[] = [];
  registry: any[] = [];
  plan: any[] = [];
  documents: any[] = [];
  buildings = [];
  propertiesComparable;
  platformId = environment.platformId;
  owners;
  developers;
  isAdmin = false;
  articles: NewsSearch;
  browserLang;
  showDetail = false;
  vacantAreaList: any = [];
  countBomaArea = false;
  marketRentList: any = [];
  occupancies: any = [];
  countBomaMarket = [];
  industryPercentagesList: any = [];
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsOccupancy: Partial<ChartOptions>;
  public chartOptionsPie: Partial<ChartOptions>;
  tenantAreas: [] = [];
  showShortDesciption = true;
  hiddeMoreFeatures;
  pointOfInterest = [];
  vacancySpot: any;
  typeBar = 1;
  marketCardList = [];
  marketImgCard: string[];
  products: ProductItem[];
  currentCode: string;
  listFeatures: any = [];
  listManager: any = [];
  unitsMix: any = [];
  unitMixColVisibility: Map<string, boolean> = new Map();
  featuresOptions: BuildingFeatureDetails[] = [];
  validateMarketRent = [];
  showPhone = false;
  showEmail = false;
  rentRolls = [];
  currentFundsBuilding = [];
  neighborhoodDescription;
  customPropertyFeatures: PropertyOptions[] = [];
  keyFactsMultifamilyOpts: PropertyOptions[] = [];
  areasMeasurementsOpts: PropertyOptions[] = [];

  @ViewChild("chartModal", { static: false }) chartModal!: ElementRef;

  get isBomaMarket() {
    return this.countBomaMarket && this.countBomaMarket.length > 0;
  }

  constructor(
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private newsService: NewsService,
    private newsNavigate: NewsNavigateService,
    private siilaTranslationService: SiilaTranslationService,
    private datePipe: DatePipe,
    private i18: I18nService,
    private metaService: MetaService,
    private featureListingService: FeaturedListingService,
    private deviceRegistration: DeviceRegistrationService,
    private accountService: AccountService,
    private sanitizer: DomSanitizer,
    private productService: ProductService,
    private propertyFeaturesService: PropertyFeaturesService,
    private commonService: CommonGenericService,
    private modalService: NgbModal,
    private localNumberPipe: LocalNumberPipeSpot
  ) {
    this.breakpointObserver
      .observe(["(max-width: 450px)"])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.chartOptions = apexObj as ChartOptions;
    this.chartOptionsOccupancy = apexObj2 as ChartOptions;
    this.marketImgCard = [
      "assets/img/products-list/indices.png",
      "assets/img/products-list/investors.png",
      "assets/img/products-list/grocs.png"
    ];
    this.currentCode = environment.platformId;
  }

  async ngOnInit() {
    if (this.validateSesion()) {
      this.route.params.subscribe(params => {
        this.id = params.id;
        this.propertyService.getPropertyId(this.id).then(resp => {
          this.property = resp;
          this.neighborhoodDescription = this.siilaTranslationService.getTranslation(
            this.property?.neighborhoodDescription
          );

          this.sanitizeDescription();
          this.metaService.setTitle(
            this.i18.getTranslation(this.property.title)
          );

          if (this.isMultifamily()) {
            this.keyFactsMultifamilyOpts = [];

            var classKeyFactsOpt: PropertyOptions = {
              name: "classType",
              value: this.property?.classType?.name,
              displayName: this.i18.get("detail.class"),
              enabled: this.property?.classType
            };
            this.keyFactsMultifamilyOpts.push(classKeyFactsOpt);
            var countryStateKeyFactsOpt: PropertyOptions = {
              name: "countryState",
              value: this.i18.getTranslation(
                this.property?.city?.countryState?.name
              ),
              displayName: this.i18.get("detail.state"),
              enabled: this.property?.city?.countryState
            };
            this.keyFactsMultifamilyOpts.push(countryStateKeyFactsOpt);
            var postalCodeKeyFactsOpt: PropertyOptions = {
              name: "postalCode",
              value: this.property?.postalCode,
              displayName: this.i18.get("detail.postalCode"),
              enabled: this.property?.postalCode
            };
            this.keyFactsMultifamilyOpts.push(postalCodeKeyFactsOpt);
            var neighborhoodKeyFactsOpt: PropertyOptions = {
              name: "neighborhood",
              value: this.i18.getTranslation(this.property?.region?.name),
              displayName: this.i18.get("detail.neighborhood"),
              enabled: this.property?.region?.name
            };
            this.keyFactsMultifamilyOpts.push(neighborhoodKeyFactsOpt);
            var latlongKeyFactsOpt: PropertyOptions = {
              name: "latlong",
              value: this.property?.latitude + "/" + this.property?.longitude,
              displayName: this.i18.get("detail.latLong"),
              enabled: this.property?.latitude && this.property?.longitude
            };
            this.keyFactsMultifamilyOpts.push(latlongKeyFactsOpt);
            var statusKeyFactsOpt: PropertyOptions = {
              name: "status",
              value: this.i18.getTranslation(
                this.property?.industrialStatus?.name
              ),
              displayName: this.i18.get("detail.status"),
              enabled: this.property?.industrialStatus?.name
            };
            this.keyFactsMultifamilyOpts.push(statusKeyFactsOpt);
            var apartmentTypeKeyFactsOpt: PropertyOptions = {
              name: "apartmentType",
              value: this.i18.getTranslation(
                this.property?.buildingSubType?.name
              ),
              displayName: this.i18.get("detail.apartmentType"),
              enabled: this.property?.buildingSubType?.name
            };
            this.keyFactsMultifamilyOpts.push(apartmentTypeKeyFactsOpt);
            var rentalTypeKeyFactsOpt: PropertyOptions = {
              name: "rentalType",
              value: this.i18.get(
                `detail.rentalTypeOpts.${this.property?.rentalType}`
              ),
              displayName: this.i18.get("detail.rentalType"),
              enabled: this.property?.rentalType
            };
            this.keyFactsMultifamilyOpts.push(rentalTypeKeyFactsOpt);
            var deliveryDateKeyFactsOpt: PropertyOptions = {
              name: "deliveryDate",
              value: this.formatDates(this.property?.deliveredDate),
              displayName: this.i18.get("detail.deliveryDate"),
              enabled: this.property?.deliveredDate
            };
            this.keyFactsMultifamilyOpts.push(deliveryDateKeyFactsOpt);
            var plannedDeliveryDateKeyFactsOpt: PropertyOptions = {
              name: "plannedDeliveryDate",
              value: this.formatDates(this.property?.planneddeliveryDate),
              displayName: this.i18.get("detail.planneddeliveryDate"),
              enabled:
                this.property?.planneddeliveryDate &&
                (!this.property?.industrialStatus ||
                  this.property?.industrialStatus?.id != 1)
            };
            this.keyFactsMultifamilyOpts.push(plannedDeliveryDateKeyFactsOpt);
            var renovationDateKeyFactsOpt: PropertyOptions = {
              name: "renovationDate",
              value: this.formatDates(this.property?.retrofitDate),
              displayName: this.i18.get("detail.renovationDate"),
              enabled: this.property?.retrofitDate
            };
            this.keyFactsMultifamilyOpts.push(renovationDateKeyFactsOpt);

            var recordStatusKeyFactsOpt: PropertyOptions = {
              name: "recordStatus",
              value: this.property?.status,
              displayName: this.i18.get("detail.recordStatus"),
              enabled: this.property?.status
            };
            this.keyFactsMultifamilyOpts.push(recordStatusKeyFactsOpt);

            this.propertyService
              .getBuildingOccupanciesRegular(this.id)
              .then(resp => {
                this.areasMeasurementsOpts = this.generateAreasMeasurementsOpts(
                  this.property,
                  resp
                );
              });

            this.propertyService.getUnitMixByPropertyId(this.id).then(resp => {
              this.unitsMix = resp;
              this.unitsMix = this.unitsMix.map(um => {
                um.floorPlan = this.i18.getTranslation(
                  um.floorPlanId.displayName
                );
                if (um.detailsId) {
                  um.detailsName = this.i18.getTranslation(
                    um.detailsId.displayName
                  );
                }
                um.monthlyrentsm =
                  um.cqAvgMonthlyRent && um.cqAvgMonthlyRent > 0
                    ? um.cqAvgMonthlyRent / um.avgArea
                    : undefined;
                um.monthlyrentsmf =
                  um.cqAvgMonthlyRentFurnished &&
                  um.cqAvgMonthlyRentFurnished > 0
                    ? um.cqAvgMonthlyRentFurnished / um.avgArea
                    : undefined;

                um.cqAvgMonthlyRent = this.commonService.formatNumberTo(
                  um.cqAvgMonthlyRent
                );
                um.monthlyrentsm = this.commonService.formatNumberTo(
                  um.monthlyrentsm
                );
                um.cqAvgMonthlyRentFurnished = this.commonService.formatNumberTo(
                  um.cqAvgMonthlyRentFurnished
                );
                um.monthlyrentsmf = this.commonService.formatNumberTo(
                  um.monthlyrentsmf
                );

                return um;
              });
              this.unitMixColVisibility = this.createColumnVisibilityMap(
                this.unitsMix
              );
            });

            this.propertyService
              .getFeatureDetailsByPropertyId(
                this.id,
                this.i18.getCurrentLanguage()
              )
              .then((resp: BuildingFeatureDetails[]) => {
                this.featuresOptions = resp;

                this.customPropertyFeatures = this.propertyFeaturesService.buildMultifamilyPropertyFeatures(
                  this.property,
                  this.featuresOptions
                );
              });
          }

          if (this.platformId == "mx") {
            this.propertyService
              .allFundBuildingsByBuilding(this.id)
              .then((fund: any) => {
                this.currentFundsBuilding = fund;
                console.log("mire ps", this.currentFundsBuilding);
              });
          }
          this.splitOwners(this.property.owners);
          this.splitDevelopers(this.property.developers);
          zip(
            this.propertyService.getProperties(
              this.property?.classType?.id
                ? [this.property?.classType.id]
                : null,
              this.property?.region?.id ? [this.property.region.id] : null,
              this.property.buildingType.id,
              this.property?.subMarket?.id ? this.property?.subMarket?.id : null
            ),
            this.propertyService.getPhotosById(this.id),
            this.propertyService.getListingsSpot(this.id),
            this.newsService.getNewsSearch(
              "",
              0,
              15,
              null,
              null,
              null,
              this.id
            ),
            this.propertyService.getListingsSpot(this.id, true),
            this.propertyService.getBuildingRentRollsVacant(this.id),
            this.propertyService.getBuildingOccupancies(this.id),
            this.propertyService.getIndustryPercentages(this.id),
            this.propertyService.getListingsSpotVacancy(this.id),
            this.propertyService.getBuildingRentRolls(this.id),
            this.propertyService.getBuildingOccupanciesRegular(this.id)
          ).subscribe(async resp => {
            this.showDetail = true;
            this.buildings = resp[0].filter(property => property.id != this.id);
            this.setPhotosType(resp[1]);
            this.listingCards = resp[2];
            this.articles = resp[3] as NewsSearch;
            this.propertiesComparable = resp[4].filter(
              building => building.buildingId != this.id
            );
            this.vacantAreaList = resp[5];
            this.marketRentList = resp[6];
            this.industryPercentagesList = resp[7];
            this.vacancySpot = resp[8];
            this.rentRolls = resp[9];
            this.occupancies = resp[10];
            /* this.occupancies = this.occupancies.filter(item => {
              const year = new Date(item.dateSurveyed).getFullYear();
              return year < 2021;
            }); */

            this.occupancies = this.occupancies.sort(
              (a, b) =>
                new Date(b.dateSurveyed).getTime() -
                new Date(a.dateSurveyed).getTime()
            );

            this.countBomaArea = this.vacantAreaList.filter(
              boma => boma.bomaArea != null
            );

            this.countBomaMarket = this.marketRentList.filter(
              rent => rent.bomaAverageAskingRent != null
            );

            console.log("boma", this.countBomaMarket.length > 0);
            this.validateMarketRent = this.marketRentList.filter(
              market => market.marketRent != null
            );

            this.setCharOptionsPie();
            this.setOccupancyGraph();
            try {
              this.setHistoricBar(1);
            } catch (error) {
              console.error(error);
            }

            this.setTenantsIndustryPie();
            this.getMarketAnalytics();
            if (this.property.buildingType.id == 9001) {
              const points = (await this.propertyService.getPointOfInterest(
                this.id
              )) as any;
              const groups = {};
              points.forEach(item => {
                const type = item.type.name;
                if (!groups[type]) {
                  groups[type] = [];
                }
                groups[type].push(item);
              });
              this.pointOfInterest = Object.keys(groups).map(
                type => groups[type]
              );

              this.pointOfInterest.forEach(element => {
                console.log(element[0]);
              });
            }
            setTimeout(() => {
              let features = document.getElementById("features");
              this.listFeatures = features?.getElementsByTagName("div");

              let manager = document.getElementById("manager");
              this.listManager = manager?.getElementsByTagName("div");

              var listDiv = document.getElementById("measurement");
              var divs = listDiv.getElementsByTagName("div");
              if (divs.length > 30) {
                this.hiddeMoreFeatures = false;
              } else {
                this.hiddeMoreFeatures = true;
              }
            }, 200);
          });
          console.log("vea", this.property);
        });
      });
    } else {
      this.router.navigateByUrl("/");
    }
  }
  /* 
  private chartModalInstance!: Modal;
  ngAfterViewInit() {
    if (this.chartModal) {
      this.chartModalInstance = new Modal(this.chartModal.nativeElement);
    }
  } */

  formatDates(date: Date | string): string {
    if (!date) return "";

    var formatDate =
      this.i18.getCurrentLanguage() === "pt-br" ? "dd/MM/yyyy" : "MM/dd/yyyy";

    return this.datePipe.transform(date, formatDate) || "";
  }

  getMarketAnalytics() {
    this.productService.getMarketAnalytics().subscribe(products => {
      this.products = products;
      this.marketCardList = products.map((item, index) => {
        return {
          id: item.id,
          background: this.marketImgCard[index],
          src: `${environment.cloudfrontURL}${item.iconPath}/360w/${item.iconFilename}`,
          iconAltTitle: this.findIconAltTitle(item),
          title: item.productShortName,
          text: item.menuItemDescription,
          actionText: item.menuItemActionButtonText
        };
      });
      if (this.isMX()) {
        var item = {
          id: 999,
          productShortName: '{"en":"FIBRA ANALYTICS","es":"FIBRA ANALYTICS"}',
          menuItemDescription:
            '{"en":"Exclusive access to real-time financial and real estate data for Mexican FIBRAS.","es":"Acceso exclusivo a datos financieros inmobiliarios en tiempo real para los FIBRAs Mexicanos."}',
          menuItemActionButtonText: '{"en":"LEARN MORE","es":"CONOCE MÁS"}',
          src: `assets/icons/corporate_fare.svg`
        };
        var index = 2;

        this.marketCardList.push({
          id: item.id,
          background: this.marketImgCard[index],
          src: item.src,
          iconAltTitle: "",
          title: item.productShortName,
          text: item.menuItemDescription,
          actionText: item.menuItemActionButtonText
        });
      }
    });
  }

  findIconAltTitle = (item: any) => {
    let card = "imgSEO.cardicons.";
    if (item.id == 101) {
      card += "gross";
    } else if (item.id == 102) {
      card += "index";
    } else {
      card += "investorsurvey";
    }
    return this.siilaTranslationService.getTranslationFromJSONFiles(card);
  };

  openInfoCard(card: any) {
    if (card.id == 999) {
      window.open(
        `https://siila.com.mx/fibra-analytics/lang/${this.i18.getCurrentLanguage()}`,
        this.isMobile ? "_self" : "_blank"
      );
    } else {
      window.open(
        `/products/${card.id}/${this.i18.getTranslation(
          card.title
        )}/lang/${this.i18.getCurrentLanguage()}`,
        this.isMobile ? "_self" : "_blank"
      );
    }
  }
  setCharOptionsPie() {
    this.chartOptionsPie = {
      series: [],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            this.handlePieClick(
              event,
              chartContext,
              config,
              this.industryPercentagesList
            );
          }
        },
        width: 550,
        type: "pie"
      },
      labels: [],
      colors: [
        "#04354f",
        "#f90",
        "#5a86a3",
        "#A9D08E",
        "#FAAE60",
        "#515050",
        "#A84400"
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%"
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  setPhotosType(photosList) {
    this.photos = photosList.filter(photo => photo.docType.id == 1001);
    this.registry = photosList.filter(registry => registry.docType.id == 1003);
    this.plan = photosList.filter(plan => plan.docType.id == 1002);
    this.documents = photosList.filter(
      documents => documents.docType.id == 1004
    );
  }

  setHistoricBar(type) {
    let series;
    let dataColumn1;
    let dataLine2;

    const filteredData = this.marketRentList.filter(item => item.year >= 2021);
    const sortedData = filteredData.sort(
      (a, b) =>
        new Date(a.dateSurveyed).getTime() - new Date(b.dateSurveyed).getTime()
    );
    this.typeBar = type;
    let labels = sortedData.map(label => this.setQuarter(label.dateSurveyed));
    let dataLine = sortedData.map(line =>
      Math.min(Math.max(Number(line.occupancy.toFixed(0)), 0), 100)
    );

    dataLine2 = sortedData.map(line =>
      Number(line.occupancySameRegion.toFixed(0))
    );

    let dataColumn = sortedData.map(column =>
      column.marketRent
        ? this.localNumberPipe.transform(column.marketRent, "1.0-0")
        : 0
    );

    dataColumn1 = sortedData.map(column =>
      column.marketRentSameRegion
        ? this.localNumberPipe.transform(column.marketRentSameRegion, "1.0-0")
        : 0
    );

    if (type == 3) {
      (this.chartOptions.colors = ["#002060", "#46b0e1", "#7f7f7f", "#f90"]),
        (series = [
          {
            name: `${this.siilaTranslationService.getTranslation(
              this.property?.title
            )} ${this.i18.get("detail.marketRent")}`,
            type: "column",
            data: dataColumn
          },
          {
            name: `${this.siilaTranslationService.getTranslation(
              this.property?.title
            )} ${this.i18.get("detail.occupancyPercent")}`,
            type: "line",
            data: dataLine
          },
          {
            name: `${this.siilaTranslationService.getTranslation(
              this.property?.region?.name
            )} ${this.property?.classType.name} ${this.i18.get(
              "detail.marketRent"
            )}`,
            type: "column",
            data: dataColumn1
          },
          {
            name: `${this.siilaTranslationService.getTranslation(
              this.property?.region?.name
            )} ${this.property?.classType.name} ${this.i18.get(
              "detail.occupancyPercent"
            )}`,
            type: "line",
            data: dataLine2
          }
        ]);
    } else {
      this.chartOptions.colors =
        type == 1 ? ["#002060", "#46b0e1"] : ["#7f7f7f", "#f90"];
      series = [
        {
          name:
            type == 1
              ? `${this.siilaTranslationService.getTranslation(
                  this.property?.title
                )} ${this.i18.get("detail.marketRent")}`
              : `${this.siilaTranslationService.getTranslation(
                  this.property?.region?.name
                )} ${this.property?.classType.name} ${this.i18.get(
                  "detail.marketRent"
                )}`,
          type: "column",
          data: type == 1 ? dataColumn : dataColumn1
        },
        {
          name:
            type == 1
              ? `${this.siilaTranslationService.getTranslation(
                  this.property?.title
                )} ${this.i18.get("detail.occupancyPercent")}`
              : `${this.siilaTranslationService.getTranslation(
                  this.property?.region?.name
                )} ${this.property?.classType.name} ${this.i18.get(
                  "detail.occupancyPercent"
                )}`,
          type: "line",
          data: type == 1 ? dataLine : dataLine2
        }
      ];
    }
    let combinedArray = [...dataColumn, ...dataColumn1];
    let combineLine = [...dataLine, ...dataLine2];

    const maxColumn = Math.max(...combinedArray);
    const minColum = Math.min(...combinedArray);
    const max = Math.max(...combineLine);
    this.chartOptions.yaxis = [
      {
        seriesName: `${this.siilaTranslationService.getTranslation(
          this.property?.title
        )} ${this.i18.get("detail.marketRent")}`,
        title: {
          text: "Market Rent R$"
        }
      },
      {
        opposite: true,
        min: 0,
        max: 100,
        seriesName: `${this.siilaTranslationService.getTranslation(
          this.property?.title
        )} ${this.i18.get("detail.occupancyPercent")}`,
        title: {
          text: "Occupancy %"
        }
      },
      {
        seriesName: `${this.siilaTranslationService.getTranslation(
          this.property?.region?.name
        )} ${this.property?.classType.name} ${this.i18.get(
          "detail.marketRent"
        )}`,
        title: {
          text: "Market Rent R$"
        },
        show: false
      },
      {
        opposite: true,
        min: 0,
        max: 100,
        seriesName: `${this.siilaTranslationService.getTranslation(
          this.property?.region?.name
        )} ${this.property?.classType.name} ${this.i18.get(
          "detail.occupancyPercent"
        )}`,
        title: {
          text: "Occupancy % 2"
        },
        show: false
      }
    ];
    this.chartOptions.yaxis[0].min = 0;
    this.chartOptions.yaxis[0].max = maxColumn * 1.02;
    this.chartOptions.yaxis[0].labels = {
      formatter: (value: number) => {
        return this.localNumberPipe.transform(value, "1.0-0");
      }
    };
    this.chartOptions.yaxis[2].min = 0;
    this.chartOptions.yaxis[2].max = maxColumn * 1.02;
    this.chartOptions.yaxis[2].labels = {
      formatter: (value: number) => {
        return this.localNumberPipe.transform(value, "1.0-0");
      }
    };

    this.chartOptions.yaxis[1].max = 100;
    this.chartOptions.yaxis[0].title.text = this.i18.get("detail.marketRent");
    this.chartOptions.yaxis[1].title.text = this.i18.get(
      "detail.occupancyPercent"
    );
    if (dataColumn.length < 2) {
      series[0].data.push(null);
      series[0].data.push(null);
      //series[0].data.push(undefined);
      series[1].data.push(null);
      series[1].data.push(null);
      //series[1].data.push(type == 1 || type == 3 ? dataLine[0] : dataLine2[0]);
      if (type == 3) {
        series[2].data.push(undefined);
        series[2].data.push(undefined);
        //series[2].data.push(undefined);
        series[3].data.push(null);
        series[3].data.push(null);
        //series[3].data.push(0);
      }

      labels.push("\u200B");
      labels.push("\u200B");
      //labels.push("\u200B");
    }
    this.chartOptions.series = series;
    this.chartOptions.labels = labels;
  }

  setOccupancyGraph() {
    let series;
    let dataColumn1;
    let dataLine2;

    //const filteredData = this.occupancies.filter(item => item.year >= 2021);
    const filteredData = this.occupancies.filter(item => {
      const year = new Date(item.dateSurveyed).getFullYear();
      return year < 2021;
    });

    const sortedData = filteredData.sort(
      (a, b) =>
        new Date(a.dateSurveyed).getTime() - new Date(b.dateSurveyed).getTime()
    );
    let labels = sortedData.map(label => this.setQuarter(label.dateSurveyed));
    let dataLine = sortedData.map(line =>
      Math.min(Math.max(Number(line.occupancy.toFixed(0)), 0), 100)
    );

    this.chartOptionsOccupancy.colors = ["#002060", "#46b0e1"];
    series = [
      {
        name: `${this.siilaTranslationService.getTranslation(
          this.property?.title
        )} ${this.i18.get("detail.occupancyPercent")}`,
        type: "column",
        data: dataLine
      }
    ];

    this.chartOptionsOccupancy.yaxis = [
      {
        min: 0,
        max: 100,
        seriesName: `${this.siilaTranslationService.getTranslation(
          this.property?.title
        )} ${this.i18.get("detail.occupancyPercent")}`,
        title: {
          text: "Occupancy %"
        }
      }
    ];
    this.chartOptionsOccupancy.yaxis[0].min = 0;
    this.chartOptionsOccupancy.yaxis[0].max = 100;
    this.chartOptionsOccupancy.yaxis[0].title.text = this.i18.get(
      "detail.occupancyPercent"
    );

    if (dataLine.length < 2) {
      series[0].data.push(undefined);
      //series[0].data.push(undefined);
      //series[0].data.push(undefined);

      //labels.push("\u200B");
      //labels.push("\u200B");
      //labels.push("\u200B");
    }
    this.chartOptionsOccupancy.series = series;
    this.chartOptionsOccupancy.labels = labels;
  }

  resetTenantIndustry() {
    this.tenantAreas = [];
    this.setTenantsIndustryPie();
  }

  setTenantsIndustryPie() {
    let labels = this.industryPercentagesList.industryPercentages.map(label =>
      this.translateLabel(label.name).trim() == "FIRE"
        ? this.i18.get("detail.fire")
        : this.translateLabel(label.name).trim() == "TAMI"
        ? this.i18.get("detail.tami")
        : this.translateLabel(label.name)
    );
    let series = this.industryPercentagesList.industryPercentages.map(serie =>
      parseFloat(serie?.percentage.toFixed(2))
    );
    this.chartOptionsPie.labels = labels;
    this.chartOptionsPie.series = series;
  }

  handlePieClick(event, chartContext, config, industryPercentagesList) {
    const tenant =
      industryPercentagesList.industryPercentages[config.dataPointIndex];
    this.tenantAreas = industryPercentagesList.tenantAreas.filter(
      item => item.categoryName == tenant.name
    );
  }

  translateLabel(name) {
    return this.i18.getTranslation(name);
  }

  setQuarter(date) {
    let input = new Date(date);
    return `${Math.floor((input.getMonth() + 3) / 3)} ${
      this.browserLang === "pt" ? "T" : "Q"
    } ${this.datePipe.transform(input, "yyyy")}`;
  }

  async validateSesion() {
    if (localStorage.getItem("token")) {
      return this.setUser();
    } else {
      let isValidToken = this.deviceRegistration.validateCookie();

      if (isValidToken) {
        let account = await this.accountService.getAccount();
        localStorage.setItem("user", JSON.stringify(account));
        return this.setUser();
      }
    }

    return false;
  }

  setUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.isAdmin = user?.roles?.includes("ROLE_ADMIN");
    if (this.isAdmin || user?.roles?.includes("ROLE_PROPERTIES")) {
      return true;
    }

    return false;
  }

  openBi(reportName) {
    this.dialogRef = this.dialog.open(SiilaIndexReportComponent, {
      height: "80%",
      width: "80%",
      data: reportName
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  openPhotos(type) {
    this.dialogRef = this.dialog.open(ImageGalleryComponent, {
      height: this.isMobile ? "100%" : "auto",
      width: this.isMobile ? "100%" : "44%",
      maxWidth: "none",
      data: this.validatePhotosType(type),
      panelClass: "custom"
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  validatePhotosType(type) {
    switch (type) {
      case 1:
        return this.photos;
        break;
      case 2:
        return this.registry;
        break;
      case 3:
        return this.plan;
        break;
      case 4:
        return this.documents;
        break;
    }
  }

  downloadReport() {
    this.propertyService.downloadReport(this.id).then((resp: any) => {
      if (resp.success) {
        const fileUrl1 = `${environment.apiURL}/transactionPrints/files/${resp.fileName}`;
        const fileUrl2 = `${environment.apiURL}/transactionPrints/files/${resp.excelFileName}`;

        this.downloadFile(fileUrl1, resp.fileName);
        this.downloadFile(fileUrl2, resp.excelFileName);
      }
    });
  }

  downloadFile(url: string, fileName: string) {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  edit() {
    window.open(
      `${environment.serverURL}/#!/building/update/${this.id}`,
      "blank"
    );
  }

  spot() {
    window.open(`${environment.spotURL}`, "blank");
  }

  transactionsPage() {
    window.open(
      `${environment.serverURL}/#!/building/${this.id}/transactions`,
      "blank"
    );
  }

  splitOwners(owners) {
    this.owners = owners ? owners.split(",") : [];
  }

  splitDevelopers(developers) {
    this.developers = developers ? developers.split(",") : [];
  }

  openCompanySearch(item) {
    window.open(
      `${environment.serverURL}/#!/companySearch?companyName=${item}`,
      "blank"
    );
  }

  suggestEdit() {
    this.dialogRef = this.dialog.open(SendNotificationComponent, {
      data: this.property.id
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  slickNext(slick) {
    slick.slickNext();
  }

  slickPrev(slick) {
    slick.slickPrev();
  }

  headerImageNews(item: string): string {
    const url = item.replace("***", "640w");
    return `${environment.cloudfrontURL}${url}`;
  }

  openNews(id: number, title, homeNews = false) {
    if (homeNews) {
      this.router.navigateByUrl("resource");
    } else {
      this.newsNavigate.openNews(
        id,
        title.urlTitle ? title.urlTitle : title.headline,
        this.isMobile,
        "",
        false,
        this.browserLang,
        true,
        null
      );
    }
  }

  isMultifamily() {
    return this.property && this.property.buildingType.id == 9001;
  }

  openSpot() {
    window.open(environment.spotURL, "_blank");
  }

  sumBomaArea() {
    let sumBoma = 0;
    this.vacantAreaList.forEach(function(boma) {
      sumBoma += boma.bomaArea;
    });

    return sumBoma;
  }

  sumArea() {
    let sumArea = 0;
    this.vacantAreaList.forEach(function(area) {
      sumArea += area.area;
    });

    return sumArea;
  }

  sumBomaRent() {
    return (
      this.validateMarketRent[0].marketRent +
      this.property?.bomaOperatingExpenses +
      this.property?.bomaPropertyTax
    );
  }

  sumprivateRent() {
    const value =
      this.validateMarketRent[0].marketRent +
      (this.property?.monthlyManagementFees
        ? this.property?.monthlyManagementFees
        : 0) +
      (this.property?.monthlyPropertyTax
        ? this.property?.monthlyPropertyTax
        : 0);

    return isNaN(value) ? null : value;
  }

  abbreviatedText(text: string): string {
    const value = text ? text.split(" ") : [];
    if (value.length > 1) {
      const firstLetter = value[0].charAt(0);
      const lastLetter = value[value.length - 1].charAt(0);
      return `${firstLetter}${lastLetter}`;
    } else if (value.length === 1) {
      return value[0].charAt(0);
    } else {
      return text;
    }
  }

  goToCreateTransactions() {
    window.open(
      `${environment.serverURL}/#!/transactions/create/${this.id}`,
      "blank"
    );
  }

  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption;
  }

  async openVacancy(vacancy) {
    let lang = this.i18.getCurrentLanguage();

    this.featureListingService.latamCountry = null;

    let res: any = await this.featureListingService
      .getSpotBuildingDetailURLFormat(vacancy.id, lang)
      .toPromise();

    if (res) {
      let detailsURL = `${res}`;
      detailsURL += "?scrollIntoVac=true";
      window.open(detailsURL, "_blank");
    }
  }

  openTenantsOwners() {
    window.open(
      `${environment.serverURL}/#!/building/${this.id}/tenants-detail`,
      "blank"
    );
  }

  validateTrnaslate(text) {
    return this.siilaTranslationService.getTranslation(text);
  }

  findBannerImage() {
    var bannerFile;

    if (this.i18.getCurrentLanguage() === "en") {
      bannerFile = "EN_SPOT_BANNER.png";
    }
    if (this.i18.getCurrentLanguage() === "pt-br") {
      bannerFile = "PT_SPOT_BANNER.png";
    }

    if (this.i18.getCurrentLanguage() === "es") {
      bannerFile = "ES_SPOT_BANNER.png";
    }

    var fullPath = `../../../../assets/img/property-detail/${bannerFile}`;

    return fullPath;
  }

  findSpotResourceBanner() {
    var bannerFile;

    if (this.i18.getCurrentLanguage() === "en") {
      bannerFile = "EN_REsource_Banner.png";
    }
    if (this.i18.getCurrentLanguage() === "pt-br") {
      bannerFile = "PT_REsource_Banner.png";
    }
    if (this.i18.getCurrentLanguage() === "es") {
      bannerFile = "ES_REsource_Banner.png";
    }

    var fullPath = `../../../../assets/img/property-detail/${bannerFile}`;
    return fullPath;
  }

  openSpotResourceBannerLink() {
    window.open(`resource/lang/${this.i18.getCurrentLanguage()}`);
  }

  openBannerImage() {
    window.open(
      `/SPOT/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  sanitizeDescription(): void {
    this.property.notes = this.validateTrnaslate(this.property.notes);
    if (this.property.notes && this.property.notes.length > 1) {
      this.property.notes = this.sanitizer.bypassSecurityTrustHtml(
        this.property.notes
      );
    }
  }

  navigateToFund(id) {
    window.open(`${environment.serverURL}/#!/realEstateFund/${id}`, "blank");
  }

  isMX() {
    return this.platformId && this.platformId == "mx";
  }

  createColumnVisibilityMap(unitsMix: UnitMix[]): Map<string, boolean> {
    const columnMap = new Map<string, boolean>();

    // Define columns to check
    const columnsToCheck = [
      "floorPlan",
      "numberOfUnits",
      "avgArea",
      "cqAvgMonthlyRent",
      "monthlyrentsm",
      "cqAvgMonthlyRentFurnished",
      "monthlyrentsmf",
      "detailsName"
    ];

    columnsToCheck.forEach(column => columnMap.set(column, false));

    for (const unit of unitsMix) {
      for (const column of columnsToCheck) {
        const value = unit[column as keyof UnitMix];
        if (
          !columnMap.get(column) &&
          value != null &&
          value !== "" &&
          value !== 0
        ) {
          columnMap.set(column, true);
        }
      }

      if (Array.from(columnMap.values()).every(Boolean)) {
        break;
      }
    }

    return columnMap;
  }

  generateAreasMeasurementsOpts(
    property: any,
    occupancies?: any
  ): PropertyOptions[] {
    let areasMeasurementsOpts: PropertyOptions[] = [];

    var buildingAreaOpt: PropertyOptions = {
      name: "buildingArea",
      value: this.commonService.formatNumberTo(property?.rentableArea, 2),
      displayName: this.i18.get("detail.buildingArea"),
      enabled: property?.rentableArea
    };
    this.addPropertyOptionTo(buildingAreaOpt, areasMeasurementsOpts);

    var landAreaOpt: PropertyOptions = {
      name: "landArea",
      value: this.commonService.formatNumberTo(property?.landArea, 2),
      displayName: this.i18.get("detail.landAread"),
      enabled: property?.landArea
    };
    this.addPropertyOptionTo(landAreaOpt, areasMeasurementsOpts);

    var numUnitsOpt: PropertyOptions = {
      name: "numUnits",
      value: this.commonService.formatNumberTo(property?.numUnits),
      displayName: this.i18.get("detail.numUnits"),
      enabled: property?.numUnits
    };
    this.addPropertyOptionTo(numUnitsOpt, areasMeasurementsOpts);

    if (occupancies && occupancies.length > 0) {
      var latestOccupancy = occupancies[occupancies.length - 1].occupancy;

      var latestOccupancyOpt: PropertyOptions = {
        name: "latestOccupancy",
        value: this.commonService.formatNumberTo(latestOccupancy) + "%",
        displayName: this.i18.get("detail.occupancy"),
        enabled: latestOccupancy
      };
      this.addPropertyOptionTo(latestOccupancyOpt, areasMeasurementsOpts);
    }

    var parkingSpacesOpt: PropertyOptions = {
      name: "parkingSpaces",
      value: this.commonService.formatNumberTo(property?.parkingSpaces),
      displayName: this.i18.get("detail.parkingSpaces"),
      enabled: property?.parkingSpaces
    };
    this.addPropertyOptionTo(parkingSpacesOpt, areasMeasurementsOpts);

    var parkingTypeOpt: PropertyOptions = {
      name: "parkingType",
      value: this.i18.getTranslation(property?.parkingType?.name),
      displayName: this.i18.get("detail.parkingType"),
      enabled: property?.parkingType?.name
    };
    this.addPropertyOptionTo(parkingTypeOpt, areasMeasurementsOpts);

    var ceilingHeightOpt: PropertyOptions = {
      name: "ceilingHeight",
      value: this.commonService.formatNumberTo(property?.ceilingHeight, 2),
      displayName: this.i18.get("detail.ceilingHeight"),
      enabled: property?.ceilingHeight
    };
    this.addPropertyOptionTo(ceilingHeightOpt, areasMeasurementsOpts);

    var totalStoriesOpt: PropertyOptions = {
      name: "totalStories",
      value: this.commonService.formatNumberTo(property?.numberStories),
      displayName: this.i18.get("detail.totalStories"),
      enabled: property?.numberStories
    };
    this.addPropertyOptionTo(totalStoriesOpt, areasMeasurementsOpts);

    var apartmentStoriesOpt: PropertyOptions = {
      name: "apartmentStories",
      value: this.commonService.formatNumberTo(property?.apartmentStories),
      displayName: this.i18.get("detail.apartmentStories"),
      enabled: property?.apartmentStories
    };
    this.addPropertyOptionTo(apartmentStoriesOpt, areasMeasurementsOpts);

    var retailStoriesOpt: PropertyOptions = {
      name: "retailStories",
      value: this.commonService.formatNumberTo(property?.retailStories),
      displayName: this.i18.get("detail.retailStories"),
      enabled: property?.retailStories
    };
    this.addPropertyOptionTo(retailStoriesOpt, areasMeasurementsOpts);

    var parkingStoriesOpt: PropertyOptions = {
      name: "parkingStories",
      value: this.commonService.formatNumberTo(property?.parkingStories),
      displayName: this.i18.get("detail.parkingStories"),
      enabled: property?.parkingStories
    };
    this.addPropertyOptionTo(parkingStoriesOpt, areasMeasurementsOpts);

    var numElevators = this.commonService.formatNumberTo(property?.elevators);
    var numElevatorsOpt: PropertyOptions = {
      name: "numElevators",
      value: numElevators ? numElevators : 0,
      displayName: this.i18.get("detail.numElevators"),
      enabled: property?.hasElevators && numElevators && numElevators !== "0"
    };
    this.addPropertyOptionTo(numElevatorsOpt, areasMeasurementsOpts);

    return areasMeasurementsOpts;
  }

  addPropertyOptionTo(
    propertyOption: PropertyOptions,
    optionCollection: PropertyOptions[]
  ) {
    if (propertyOption.enabled) {
      optionCollection.push(propertyOption);
    }
  }

  /* openChartPopup() {
    const chartModal = new Modal(this.chartModal.nativeElement, {
      backdrop: false, // or 'true'
      keyboard: false // Prevent closing with keyboard
    });
    chartModal.show();
    NgbModal
  } */

  openChartPopup(content: any) {
    this.modalService.open(content, {
      centered: true,
      backdrop: false,
      keyboard: false
    });
  }
}
