<div class="nav-container">
  <div class="col-md-12 color-span nav-detail">
    <div class="col-md-3 f-left mt-5">
      {{ property?.buildingType?.name | langSelector }}
    </div>
    <div class="col-md-6 title-row f-left mt-1">
      <span class="title">
        {{ property?.title | langSelector }}
      </span>
      <br />
      <span>
        {{ property?.address | langSelector }},
        {{ property?.city?.name | langSelector }},
        {{ abbreviatedText(property?.city?.countryState?.name | langSelector) }}
      </span>
    </div>
    <div class="col-md-3 f-left mt-5" style="text-align: end;">
      {{ property?.region?.name | langSelector }}
    </div>
  </div>
</div>
<div class="content">
  <app-loading-spinner *ngIf="!showDetail"></app-loading-spinner>
  <div *ngIf="showDetail" class="content-detail">
    <div class="w-100">
      <app-show-images
        [photos]="photos"
        [latitude]="property?.latitude"
        [longitude]="property?.longitude"
        [buildingTypeId]="property?.buildingType.id"
        class="d-flex pl"
      ></app-show-images>
    </div>
    <div class="col-md-12 content-option mt-3">
      <div class="content-option-btn col-md-8">
        <!--   <div>
        <img
          style="width: 20px;
        margin-right: 5px;"
          src="assets/featuresIcons/analyze-2.png"
          alt=""
        /><span (click)="openBi('Building Information POC EN')">{{
          "detail.propertyAnalytics" | translate
        }}</span> 
      </div>-->
        <div
          *ngIf="!isMultifamily()"
          [ngClass]="{ 'disabled-option': !property?.transactionCount }"
        >
          <img
            style="width: 20px;
        margin-right: 5px;"
            src="assets/featuresIcons/analyze-2.png"
            alt=""
          /><span (click)="transactionsPage()">{{
            "detail.viewTransactions" | translate
          }}</span>
        </div>
        <div *ngIf="property?.buildingType.id != 9001">
          <img
            style="width: 20px;
        margin-right: 5px;"
            src="assets/featuresIcons/generate-reports.png"
            alt=""
          />
          <span (click)="downloadReport()">{{
            "detail.generateReport" | translate
          }}</span>
        </div>
        <div *ngIf="isAdmin && !isMultifamily()">
          <img
            style="width: 24px;
        margin-right: 5px;"
            src="assets/featuresIcons/flash.png"
            alt=""
          />
          <span (click)="goToCreateTransactions()">{{
            "detail.createTransactions" | translate
          }}</span>
        </div>
        <!-- <div>
        <span (click)="spot()">{{ "detail.spot" | translate }}</span>
      </div> -->
        <div (click)="isAdmin ? edit() : suggestEdit()">
          <mat-icon style="margin-right: 6px;">edit</mat-icon>
          <span>{{
            isAdmin
              ? ("detail.edit" | translate)
              : ("detail.suggestEdit" | translate)
          }}</span>
        </div>
        <div *ngIf="isMobile">
          <span (click)="openPhotos(1)">{{ "detail.photos" | translate }}</span>
        </div>
      </div>

      <div class="content-option-btn col-md-4">
        <div
          style="border: none;
          border-right: 1px solid gray;
          border-radius: inherit;margin-left: -40px;"
        ></div>
        <div *ngIf="registry.length > 0">
          <span (click)="openPhotos(2)">{{
            "detail.matricula" | translate
          }}</span>
        </div>
        <div *ngIf="plan.length > 0">
          <span (click)="openPhotos(3)">{{ "detail.plans" | translate }}</span>
        </div>
        <div *ngIf="documents.length > 0">
          <span (click)="openPhotos(4)">{{
            "detail.otherDocs" | translate
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-2">
      <div style="border-bottom: 1px solid #00334d;margin: 22px;"></div>
    </div>
    <div class="col-md-12 mt-5 content-columns">
      <div class="col-md-8">
        <div
          class="col-md-12 p-r p-html"
          *ngIf="property?.notes && validateTrnaslate(property?.notes) != ''"
        >
          <div class="col-md-12 key-facts ">
            <span>{{ "detail.propertyDescription" | translate }}</span>
          </div>

          <div class="mt-2" style="margin: 0;text-align: justify;">
            <!-- <span class="mt-2">{{ property?.notes | langSelector }}</span> -->
            <span class="mt-2" [innerHTML]="property.notes"></span>
          </div>
        </div>
        <div
          class="col-md-12 p-r dneighborDescDebug"
          *ngIf="neighborhoodDescription"
        >
          <div
            *ngIf="neighborhoodDescription"
            class="col-md-12 key-facts dneightDescTitle"
          >
            <span>{{ "detail.neighborhoodDescription" | translate }}</span>
          </div>

          <div
            class="mt-2 pneighborDescDebug"
            *ngIf="neighborhoodDescription"
            style="margin: 0;text-align: justify;"
          >
            <span class="mt-2">{{
              property?.neighborhoodDescription | langSelector
            }}</span>
          </div>
        </div>
        <div
          class="col-md-12 mt-2 p-r"
          id="features"
          [ngStyle]="{
            display: listFeatures.length > 2 ? 'block' : 'none'
          }"
          *ngIf="property?.buildingType.id != 3001 || platformId == 'mx'"
        >
          <div class="col-md-12 key-facts ">
            <span>{{ "detail.propertyFeatures" | translate }}</span>
          </div>
          <div
            *ngIf="isMultifamily() && customPropertyFeatures.length > 0"
            style="font-size: 14px;"
            class="d-flex column-features border-table"
          >
            <div
              class="column-property"
              [ngClass]="{ 'display-none': !feature?.value }"
              *ngFor="let feature of customPropertyFeatures"
            >
              <div *ngIf="feature?.enabled && feature?.value">
                <img
                  [src]="feature.iconSrc"
                  [class]="feature.customClass"
                  alt=""
                />{{ feature.displayName }}
              </div>
            </div>
          </div>
          <div *ngIf="!isMultifamily()" class="d-flex column-features">
            <div
              class="column-property"
              *ngIf="property?.twentyFourHourSecurity"
            >
              <img src="assets/featuresIcons/security.svg" alt="" />{{
                "detail.twentyFourHourSecurity" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.armoredCabin">
              <img src="assets/featuresIcons/security.svg" alt="" />{{
                "detail.bulletProofCabin" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.mixedUse">
              <img src="assets/featuresIcons/home.png" alt="" />{{
                "detail.mixedUse" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.hoursMaintenance24">
              <img src="assets/featuresIcons/build-circle.png" alt="" />{{
                "detail.24HourMaintenance" | translate
              }}
            </div>

            <div class="column-property" *ngIf="property?.bikeRental">
              <img src="assets/featuresIcons/pedal_bike.svg" alt="" />{{
                "detail.bikeRental" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.businessCenter">
              <img src="assets/featuresIcons/desktop-windows.png" alt="" />{{
                "detail.businessCenter" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.dogPark">
              <img src="assets/featuresIcons/pets.png" alt="" />{{
                "detail.dogPark" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.packageCenter">
              <img src="assets/featuresIcons/deployed-code.png" alt="" />{{
                "detail.packageCenter" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.pool">
              <img src="assets/featuresIcons/pool.png" alt="" />{{
                "detail.pool" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.retailOnSite">
              <img src="assets/featuresIcons/storefront.png" alt="" />{{
                "detail.retailOnSite" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.retailOnSite">
              <img src="assets/featuresIcons/storefront.png" alt="" />{{
                "detail.retailOnSite" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.rooftopLounge">
              <img src="assets/featuresIcons/restaurant.png" alt="" />{{
                "detail.rooftopLounge" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.bicycleRack">
              <img src="assets/featuresIcons/pedal_bike.svg" alt="" />{{
                "detail.bicycleRack" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.securityAccess">
              <img src="assets/featuresIcons/security.svg" alt="" />{{
                "detail.securityAccess" | translate
              }}
            </div>

            <div class="column-property" *ngIf="property?.sprinklers">
              <img src="assets/featuresIcons/highlight.svg" alt="" />{{
                "detail.sprinklers" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.washerDryerIncluded">
              <img src="assets/featuresIcons/laundry.png" alt="" />{{
                "detail.WasherDryerIncluded" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.washerDryerHookup">
              <img src="assets/featuresIcons/laundry.png" alt="" />{{
                "detail.WasherDryerHookup" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.furnished">
              <img src="assets/featuresIcons/home.png" alt="" />{{
                "detail.furnished" | translate
              }}
            </div>

            <div class="column-property" *ngIf="property?.crossDocking">
              <img src="assets/featuresIcons/local_shipping.png" alt="" />{{
                "detail.crossDocking" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.hasLockerRooms">
              <img src="assets/featuresIcons/living.png" alt="" />{{
                "detail.lockerRoom" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.refrigerated">
              <img src="assets/featuresIcons/thermometer_loss_.png" alt="" />{{
                "detail.refrigerated" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.skyLights">
              <img src="assets/featuresIcons/light_mode.png" alt="" />{{
                "detail.skyLights" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.sluiceGate">
              <img src="assets/featuresIcons/water_drop.png" alt="" />{{
                "detail.sluiceGate" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.truckYard">
              <img src="assets/featuresIcons/local_shipping.png" alt="" />{{
                "detail.truckYard" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.plugAndPlay">
              <img
                src="assets/featuresIcons/plug-icon-color-SiiLA.png"
                alt=""
              />
              {{ "detail.plugAndPlay" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.coreAndShel">
              <img src="assets/featuresIcons/warehouse-SiiLA.png" alt="" />
              {{ "detail.coreAndShel" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.heliport">
              <img src="assets/featuresIcons/helicopter.png" alt="" />{{
                "detail.heliport" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.leedStatus">
              <img src="assets/featuresIcons/eco.svg" alt="" />{{
                "detail.leedStatus" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.electricGenerator">
              <img
                src="assets/featuresIcons/electrical_services.svg"
                alt=""
              />{{ "detail.electricGenerator" | translate }}
            </div>
            <div
              class="column-property"
              *ngIf="property?.centralAirConditioning"
            >
              <img src="assets/featuresIcons/ac_unit.svg" alt="" />{{
                "detail.centralAirConditioning" | translate
              }}
            </div>
            <div class="column-property" *ngIf="property?.raisedFloor">
              <img
                src="assets/featuresIcons/plug-icon-color-SiiLA.png"
                alt=""
              />
              {{ "detail.raisedFloor" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.neverUsed">
              <img src="assets/featuresIcons/certified.png" alt="" />
              {{ "detail.neverUsed" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.hasRestrooms">
              <img src="assets/featuresIcons/restroom-icon.png" alt="" />
              {{ "detail.hasRestrooms" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.hasElevators">
              <img src="assets/featuresIcons/elevator.png" alt="" />
              {{ "detail.elevators" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.pool">
              <img src="assets/featuresIcons/pool.png" alt="" />
              {{ "detail.pool" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.fitnessCenter">
              <img src="assets/featuresIcons/fitness_center.png" alt="" />
              {{ "detail.FitnessCenter" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.playground">
              <img src="assets/featuresIcons/restroom-icon.png" alt="" />
              {{ "detail.playGround" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.dogPark">
              <img src="assets/featuresIcons/restroom-icon.png" alt="" />
              {{ "detail.dogPark" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.petFriendly">
              <img src="assets/featuresIcons/pets.png" alt="" />
              {{ "detail.petFriendly" | translate }}
            </div>
            <div class="column-property" *ngIf="property?.ceilingFans">
              <img src="assets/featuresIcons/restroom-icon.png" alt="" />
              {{ "detail.ceilingFans" | translate }}
            </div>
          </div>
        </div>
        <div class=" key-columns">
          <div class="col-md-6" [ngClass]="{ 'p-0': isMobile }">
            <div class="col-md-12 key-facts mt-2">
              <span>{{ "detail.keyFacts" | translate }}</span>
            </div>
            <div
              *ngIf="isMultifamily()"
              id="key-facts-multicolumns"
              class="col-md-12 border-table p-0"
            >
              <div
                *ngFor="let keyFact of keyFactsMultifamilyOpts"
                [ngClass]="{ 'display-none': !keyFact?.enabled }"
                class="key-facts-multicolumns"
              >
                <div *ngIf="keyFact.enabled" class="column label-color">
                  {{ keyFact.displayName }}
                </div>
                <div class="column key-facts-value label-color2">
                  {{ keyFact.value }}
                </div>
              </div>
            </div>
            <div
              *ngIf="!isMultifamily()"
              class="d-flex key-facts-columns border-table"
            >
              <div class="column label-color" *ngIf="property?.classType?.name">
                {{ "detail.class" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="property?.classType?.name"
              >
                {{ property?.classType?.name }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.city?.countryState?.name"
              >
                {{ "detail.state" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="property?.city?.countryState?.name"
              >
                {{ property?.city?.countryState?.name | langSelector }}
              </div>
              <div class="column label-color" *ngIf="property?.postalCode">
                {{ "detail.postalCode" | translate }}
              </div>
              <div class="column label-color2" *ngIf="property?.postalCode">
                {{ property?.postalCode }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.region?.name || property?.subMarket?.name"
              >
                {{
                  platformId == "br"
                    ? ("detail.region" | translate)
                    : ("detail.subMarket" | translate)
                }}
              </div>
              <div
                class="column label-color2"
                *ngIf="property?.region?.name || property?.subMarket?.name"
              >
                {{
                  platformId == "br"
                    ? (property?.region?.name | langSelector)
                    : property?.subMarket.name
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="currentFundsBuilding.length > 0 && platformId == 'mx'"
              >
                {{ "detail.fibra" | translate }}
              </div>
              <div
                class="column label-color2 align-dev-own"
                style="text-align: end;"
                *ngIf="currentFundsBuilding.length > 0 && platformId == 'mx'"
              >
                <div
                  *ngFor="let fund of currentFundsBuilding; let i = index"
                  (click)="navigateToFund(fund.fund.id)"
                >
                  <span>{{ fund.fund.name | langSelector }} </span>
                  <span>({{ fund.ownershipPercentage | localNumber }} %)</span>
                </div>
              </div>
              <div
                class="column label-color"
                *ngIf="property?.subMarket?.market.name"
              >
                {{ "detail.market" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="property?.subMarket?.market.name"
              >
                {{ property?.subMarket?.market.name | langSelector }}
              </div>
              <div *ngIf="property?.latitude" class="column label-color">
                {{ "detail.latLong" | translate }}
              </div>
              <div *ngIf="property?.latitude" class="column label-color2">
                {{ property?.latitude }}/{{ property?.longitude }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.industrialStatus?.name"
              >
                {{ "detail.status" | translate }}
              </div>

              <div
                class="column label-color2"
                *ngIf="property?.industrialStatus?.name"
              >
                {{ property?.industrialStatus?.name | langSelector }}
              </div>
              <div *ngIf="property?.buildingSubType" class="column label-color">
                {{ "detail.properySubtype" | translate }}
              </div>
              <div
                *ngIf="property?.buildingSubType"
                class="column label-color2"
              >
                {{ property?.buildingSubType?.name | langSelector }}
              </div>
              <div *ngIf="property?.tenancy?.name" class="column label-color ">
                {{ "detail.multiTenant" | translate }}
              </div>
              <div *ngIf="property?.tenancy?.name" class="column label-color2">
                {{ property?.tenancy.name | langSelector }}
              </div>
              <div *ngIf="property?.deliveredDate" class="column label-color">
                {{ "detail.deliveryDate" | translate }}
              </div>
              <div *ngIf="property?.deliveredDate" class="column label-color2">
                {{
                  browserLang == "pt"
                    ? (property?.deliveredDate | date: "dd/MM/yyyy")
                    : (property?.deliveredDate | date: "MM/dd/yyyy")
                }}
              </div>
              <div
                *ngIf="property?.planneddeliveryDate"
                class="column label-color"
              >
                {{ "detail.planneddeliveryDate" | translate }}
              </div>
              <div
                *ngIf="property?.planneddeliveryDate"
                class="column label-color2"
              >
                {{
                  browserLang == "pt"
                    ? (property?.planneddeliveryDate | date: "dd/MM/yyyy")
                    : (property?.planneddeliveryDate | date: "MM/dd/yyyy")
                }}
              </div>
              <div
                *ngIf="property?.lastExpansionDeliveryDate"
                class="column label-color"
              >
                {{ "detail.ExpansionDeliveryDate" | translate }}
              </div>
              <div
                *ngIf="property?.lastExpansionDeliveryDate"
                class="column label-color2"
              >
                {{
                  browserLang == "pt"
                    ? (property?.lastExpansionDeliveryDate | date: "dd/MM/yyyy")
                    : (property?.lastExpansionDeliveryDate | date: "MM/dd/yyyy")
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.status && isAdmin"
              >
                {{ "detail.recordStatus" | translate }}
              </div>

              <div
                class="column label-color2"
                *ngIf="property?.status && isAdmin"
              >
                {{ property?.status }}
              </div>

              <div class="column label-color" *ngIf="property?.retrofitDate">
                {{ "detail.retrofitDate" | translate }}
              </div>

              <div class="column label-color2" *ngIf="property?.retrofitDate">
                {{ property?.retrofitDate }}
              </div>
              <div class="column label-color" *ngIf="property?.neighborhood">
                {{ "detail.neighborhood" | translate }}
              </div>

              <div class="column label-color2" *ngIf="property?.neighborhood">
                {{ property?.neighborhood }}
              </div>
            </div>
            <div
              *ngIf="
                property?.buildingType.id != 9001 &&
                property.buildingType.id != 3001 &&
                validateMarketRent.length > 0
              "
              class="col-md-12 mt-2"
              style="padding: 0px;"
            >
              <div class="col-md-12 key-facts">
                <span>{{ "detail.marketRentTax" | translate }}</span>
              </div>
              <div class=" table-vacant" style="overflow: hidden;">
                <table class="table">
                  <tbody class=" border-table">
                    <tr>
                      <td class="label-color">
                        {{ "detail.rent" | translate }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="validateMarketRent.length > 0"
                      >
                        {{ validateMarketRent[0].marketRent | localNumber }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="
                          marketRentList[marketRentList.length - 1]
                            ?.averageMonthlyAskingRentPerArea
                        "
                      >
                        {{
                          marketRentList[marketRentList.length - 1]
                            .averageMonthlyAskingRentPerArea | localNumber
                        }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        property?.bomaOperatingExpenses ||
                        property?.monthlyManagementFees
                      "
                    >
                      <td class="label-color">
                        {{ "detail.operatingExpenses" | translate }}
                        {{ !isBomaMarket ? "" : "(BOMA)" }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="isBomaMarket"
                      >
                        {{ property?.bomaOperatingExpenses | localNumber }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="!isBomaMarket"
                      >
                        {{ property?.monthlyManagementFees | localNumber }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        property?.bomaPropertyTax ||
                        property?.monthlyPropertyTax
                      "
                    >
                      <td class="label-color">
                        {{ "detail.propertyTax" | translate }}
                        {{ !isBomaMarket ? "" : "(BOMA)" }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="isBomaMarket"
                      >
                        {{ property?.bomaPropertyTax | localNumber }}
                      </td>
                      <td
                        class="label-color2"
                        style="text-align: end;"
                        *ngIf="!isBomaMarket"
                      >
                        {{ property?.monthlyPropertyTax | localNumber }}
                      </td>
                    </tr>
                    <tr>
                      <td class="total-row" style="font-weight: 600;">
                        Total R$/m²
                      </td>
                      <td
                        *ngIf="isBomaMarket"
                        style="text-align: end;font-weight: 600;"
                        class="total-row"
                      >
                        {{ sumBomaRent() | localNumber }}
                      </td>
                      <td
                        *ngIf="!isBomaMarket && validateMarketRent.length > 0"
                        class="total-row"
                        style="text-align: end;font-weight: 600;"
                      >
                        {{ sumprivateRent() | localNumber }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              id="manager"
              class="propertyManagerDeb"
              [ngStyle]="{
                display: listManager.length > 2 ? 'block' : 'none'
              }"
            >
              <div class="col-md-12 key-facts mt-2">
                <span>{{
                  "detail.propertyManagerInformation" | translate
                }}</span>
              </div>
              <div class="d-flex key-facts-columns border-table">
                <div
                  class="column label-color"
                  *ngIf="property?.propertyManagementCompany"
                >
                  {{ "detail.propertyManager" | translate }}
                </div>
                <div
                  class="column label-color2 align-dev-own"
                  *ngIf="property?.propertyManagementCompany"
                  style="text-align: right;"
                >
                  <span
                    (click)="
                      openCompanySearch(property?.propertyManagementCompany)
                    "
                  >
                    {{ property?.propertyManagementCompany }}
                  </span>
                </div>
                <div
                  class="column label-color"
                  *ngIf="property?.managerTelephone"
                >
                  {{ "detail.telephoneNumber" | translate }}
                </div>
                <div
                  class="column label-color2"
                  style="color: #2c94d3"
                  *ngIf="property?.managerTelephone"
                >
                  <img
                    class="show-data-img"
                    src="assets/featuresIcons/call_icon.png"
                    alt=""
                  /><span
                    *ngIf="!showPhone"
                    (click)="showPhone = !showPhone"
                    style="cursor: pointer;"
                    >{{ "detail.showPhone" | translate }}</span
                  >
                  <span *ngIf="showPhone">
                    {{ property?.managerTelephone }}</span
                  >
                </div>
                <div class="column label-color" *ngIf="property?.managerEmail">
                  {{ "detail.email" | translate }}
                </div>
                <div
                  class="column label-color2"
                  style="    width: 50%;
                word-wrap: break-word;color: #2c94d3"
                  *ngIf="property?.managerEmail"
                >
                  <span
                    *ngIf="!showEmail"
                    (click)="showEmail = !showEmail"
                    style="cursor: pointer;"
                    ><img
                      class="show-data-img"
                      src="assets/featuresIcons/mail_icon.png"
                      alt=""
                    />{{ "detail.showEmail" | translate }}</span
                  >
                  <span *ngIf="showEmail"
                    ><img
                      class="show-data-img"
                      src="assets/featuresIcons/mail_icon.png"
                      alt=""
                    />{{ property?.managerEmail }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 p-r">
            <div class="col-md-12 key-facts mt-2">
              <span>{{ "detail.areasMeasurements" | translate }}</span>
            </div>
            <div
              *ngIf="isMultifamily()"
              id="areas-measurements-multicolumns"
              [ngClass]="{
                'show-less': areasMeasurementsOpts.length > 10
              }"
              class="col-md-12 border-table p-0"
            >
              <div
                *ngFor="let keyFact of areasMeasurementsOpts"
                [ngClass]="{ 'display-none': !keyFact?.enabled }"
                class="key-facts-multicolumns"
              >
                <div *ngIf="keyFact.enabled" class="column label-color">
                  {{ keyFact.displayName }}
                </div>
                <div class="column key-facts-value label-color2">
                  {{ keyFact.value }}
                </div>
              </div>
            </div>
            <div
              *ngIf="!isMultifamily()"
              class="d-flex key-facts-columns border-table"
              id="measurement"
              [ngClass]="{
                'show-less': !hiddeMoreFeatures
              }"
            >
              <div class="column label-color" *ngIf="property?.rentableArea">
                {{
                  property?.buildingType.id == 2001
                    ? ("detail.privateTotalAreas" | translate)
                    : ("detail.totalArea" | translate)
                }}
              </div>
              <div class="column label-color2" *ngIf="property?.rentableArea">
                {{ property?.rentableArea | localNumber }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  property?.buildingType.id == 1001 &&
                  property?.rentableArea &&
                  platformId == 'mx'
                "
              >
                {{ "detail.industrialSpaceSizeFt" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  property?.buildingType.id == 1001 &&
                  property?.rentableArea &&
                  platformId == 'mx'
                "
              >
                {{ property?.rentableArea * 10.76391 | localNumber }}
              </div>

              <div
                class="column label-color"
                *ngIf="
                  property?.buildingType.id == 1001 &&
                  property?.officeArea &&
                  platformId == 'mx'
                "
              >
                {{ "detail.officeArea" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  property?.buildingType.id == 1001 &&
                  property?.officeArea &&
                  platformId == 'mx'
                "
              >
                {{ property?.officeArea | localNumber }}
              </div>

              <div
                class="column label-color"
                *ngIf="
                  property?.buildingType.id == 1001 &&
                  property?.officeArea &&
                  platformId == 'mx'
                "
              >
                {{ "detail.roundedOfficeAreaFt" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  property?.buildingType.id == 1001 &&
                  property?.officeArea &&
                  platformId == 'mx'
                "
              >
                {{ property?.officeArea * 10.76391 | localNumber }}
              </div>

              <div
                class="column label-color"
                *ngIf="
                  property?.buildingType.id == 1001 &&
                  property?.rentableArea &&
                  property?.officeArea &&
                  platformId == 'mx'
                "
              >
                {{ "detail.roundedOfficeAreaFt" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  property?.buildingType.id == 1001 &&
                  property?.rentableArea &&
                  property?.officeArea &&
                  platformId == 'mx'
                "
              >
                {{
                  (property?.officeArea / property?.rentableArea) * 100
                    | localNumber
                }}
                %
              </div>

              <div
                class="column label-color"
                *ngIf="property?.constructionArea"
              >
                {{ "detail.constructionArea" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="property?.constructionArea"
              >
                {{ property?.constructionArea | localNumber }}
              </div>
              <div
                class="column label-color"
                *ngIf="
                  property?.efficiencyRatio &&
                  property?.rentableArea &&
                  property?.constructionArea
                "
              >
                {{ "detail.efficiencyRatio" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  property?.efficiencyRatio &&
                  property?.rentableArea &&
                  property?.constructionArea
                "
              >
                {{
                  property?.rentableArea / property?.constructionArea
                    | localNumber
                }}
              </div>
              <div class="column label-color" *ngIf="property?.floorArea">
                {{ "detail.floorArea" | translate }}
              </div>
              <div class="column label-color2" *ngIf="property?.floorArea">
                {{ property?.floorArea | localNumber }}
              </div>
              <div
                class="column label-color"
                *ngIf="property?.industrialStatus"
              >
                {{ "detail.status" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="property?.industrialStatus"
              >
                {{ property?.industrialStatus.name | langSelector }}
              </div>
              <div class="column label-color" *ngIf="property?.otherStories">
                {{ "detail.otherStories" | translate }}
              </div>
              <div class="column label-color2" *ngIf="property?.otherStories">
                {{ property?.otherStories | localNumber }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.averageFloorPrivateArea
                "
                class="column label-color"
              >
                {{ "detail.avgFloorPrivateArea" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.averageFloorPrivateArea
                "
                class="column label-color2"
              >
                {{ property?.averageFloorPrivateArea | localNumber }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.privateAvailableArea
                "
                class="column label-color"
              >
                {{ "detail.privateAvailableArea" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.privateAvailableArea
                "
                class="column label-color2"
              >
                {{ property?.privateAvailableArea | localNumber }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 && property?.bomaTotalArea
                "
                class="column label-color"
              >
                {{ "detail.bomaTotalArea" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 && property?.bomaTotalArea
                "
                class="column label-color2"
              >
                {{ property?.bomaTotalArea | localNumber }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.averageFloorBomaArea
                "
                class="column label-color"
              >
                {{ "detail.avgFloorBomaArea" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.averageFloorBomaArea
                "
                class="column label-color2"
              >
                {{ property?.averageFloorBomaArea | localNumber }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.bomaAvailableArea
                "
                class="column label-color"
              >
                {{ "detail.bomaAvailableArea" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.bomaAvailableArea
                "
                class="column label-color2"
              >
                {{ property?.bomaAvailableArea | localNumber }}
              </div>
              <div
                *ngIf="property?.buildingType.id != 2001 && property?.landArea"
                class="column label-color"
              >
                {{ "detail.landAread" | translate }}
              </div>
              <div
                *ngIf="property?.buildingType.id != 2001 && property?.landArea"
                class="column label-color2"
              >
                {{ property?.landArea | localNumber }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id != 2001 &&
                  property?.buildingType.id != 3001 &&
                  property?.landArea &&
                  platformId == 'mx'
                "
                class="column label-color"
              >
                {{ "detail.landAreaFt" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id != 2001 &&
                  property?.buildingType.id != 3001 &&
                  property?.landArea &&
                  platformId == 'mx'
                "
                class="column label-color2"
              >
                {{ property?.landArea * 10.76391 | localNumber }}
              </div>
              <div *ngIf="property?.drivingDistance" class="column label-color">
                {{ "detail.drivingDistance" | translate }}
              </div>
              <div
                *ngIf="property?.drivingDistance"
                class="column label-color2"
              >
                {{ property?.drivingDistance | localNumber }}
              </div>
              <div
                *ngIf="
                  (property?.buildingType.id == 2001 ||
                    property?.buildingType.id == 9001) &&
                  property?.numberStories
                "
                class="column label-color"
              >
                {{ "detail.stories" | translate }}
              </div>
              <div
                *ngIf="
                  (property?.buildingType.id == 2001 ||
                    property?.buildingType.id == 9001) &&
                  property?.numberStories
                "
                class="column label-color2"
              >
                {{ property?.numberStories }}
              </div>
              <div *ngIf="property?.warehouse" class="column label-color">
                {{ "detail.warehouse" | translate }}
              </div>
              <div *ngIf="property?.warehouse" class="column label-color2">
                {{ property?.warehouse }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id != 3001 && property?.ceilingHeight
                "
                class="column label-color"
              >
                {{ "detail.ceilingHeight" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id != 3001 && property?.ceilingHeight
                "
                class="column label-color2"
              >
                {{ property?.ceilingHeight | localNumber }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id != 3001 &&
                  property?.ceilingHeight &&
                  platformId == 'mx'
                "
                class="column label-color"
              >
                {{ "detail.ceilingHeightFt" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id != 3001 &&
                  property?.ceilingHeight &&
                  platformId == 'mx'
                "
                class="column label-color2"
              >
                {{ property?.ceilingHeight * 3.28084 | localNumber }}
              </div>
              <div *ngIf="property?.tickerSymbol" class="column label-color">
                {{ "detail.tickerSymbol" | translate }}
              </div>
              <div *ngIf="property?.tickerSymbol" class="column label-color2">
                {{ property?.tickerSymbol }}
              </div>
              <div
                *ngIf="property?.fiiOwnershipPercentage"
                class="column label-color"
              >
                {{ "detail.fiiOwnership" | translate }}
              </div>
              <div
                *ngIf="property?.fiiOwnershipPercentage"
                class="column label-color2"
              >
                {{ property?.fiiOwnershipPercentage | localNumber }}%
              </div>
              <div
                *ngIf="property?.buildingType.id == 1001 && property?.floorLoad"
                class="column label-color"
              >
                {{
                  platformId == "mx"
                    ? ("detail.floorLoadmx" | translate)
                    : ("detail.floorLoad" | translate)
                }}
              </div>
              <div
                *ngIf="property?.buildingType.id == 1001 && property?.floorLoad"
                class="column label-color2"
              >
                {{ property?.floorLoad }}
              </div>
              <div *ngIf="property?.leedType" class="column label-color">
                {{ "detail.leedType" | translate }}
              </div>
              <div
                *ngIf="property?.leedType"
                class="column label-color2 leetypename"
              >
                {{ property?.leedType.name | langSelector }}
              </div>
              <div
                class="featureoptionscontainer"
                *ngFor="let feature of featuresOptions"
              >
                <div class="column label-color">
                  {{ feature.featureDisplayName }}
                </div>
                <div class="column label-color2 feature-options">
                  <span *ngFor="let option of feature.featureSelectedOptions">
                    {{ option }}
                  </span>
                </div>
              </div>

              <div
                *ngIf="property?.fireProtectionType?.name"
                class="column label-color"
              >
                {{ "detail.fireProtectionType" | translate }}
              </div>
              <div
                *ngIf="property?.fireProtectionType?.name"
                class="column label-color2"
              >
                {{ property?.fireProtectionType?.name | langSelector }}
              </div>

              <div
                *ngIf="
                  property?.fireProtection || property?.fireProtection == false
                "
                class="column label-color"
              >
                {{ "detail.fireProtection" | translate }}
              </div>
              <div
                *ngIf="
                  property?.fireProtection || property?.fireProtection == false
                "
                class="column label-color2"
              >
                {{
                  property?.fireProtection
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="property?.buildingType.id == 1001 && property?.docks"
                class="column label-color"
              >
                {{ "detail.numberOfDocks" | translate }}
              </div>
              <div
                *ngIf="property?.buildingType.id == 1001 && property?.docks"
                class="column label-color2"
              >
                {{ property?.docks }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 3001 && property?.majorTenants
                "
                class="column label-color"
              >
                {{ "detail.majorTenants" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 3001 && property?.majorTenants
                "
                class="column label-color2"
              >
                {{ property?.majorTenants }}
              </div>
              <div
                *ngIf="
                  (property?.buildingType.id == 3001 ||
                    property?.buildingType.id == 9001) &&
                  property?.numberOfTenants
                "
                class="column label-color"
              >
                {{ "detail.numberOfTenants" | translate }}
              </div>
              <div
                *ngIf="
                  (property?.buildingType.id == 3001 ||
                    property?.buildingType.id == 9001) &&
                  property?.numberOfTenants
                "
                class="column label-color2"
              >
                {{ property?.numberOfTenants }}
              </div>

              <div
                class="column label-color"
                *ngIf="property?.parkingType?.name"
              >
                {{ "detail.parkingType" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="property?.parkingType?.name"
              >
                {{ property?.parkingType?.name | langSelector }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.industrialParkingSpaces
                "
                class="column label-color"
              >
                {{ "detail.truckParkingSpaces" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id == 2001 &&
                  property?.industrialParkingSpaces
                "
                class="column label-color2"
              >
                {{ property?.industrialParkingSpaces | localNumber: "1.0-0" }}
              </div>
              <div
                *ngIf="property?.carParkingSpaces"
                class="column label-color "
              >
                {{ "detail.carParkingSpaces" | translate }}
              </div>
              <div
                *ngIf="property?.carParkingSpaces"
                class="column label-color2"
              >
                {{ property?.carParkingSpaces | localNumber: "1.0-0" }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id != 1001 && property?.parkingSpaces
                "
                class="column label-color"
              >
                {{ "detail.parkingSpaces" | translate }}
              </div>
              <div
                *ngIf="
                  property?.buildingType.id != 1001 && property?.parkingSpaces
                "
                class="column label-color2"
              >
                {{ property?.parkingSpaces | localNumber: "1.0-0" }}
              </div>
              <div *ngIf="property?.numUnits" class="column label-color">
                {{ "detail.numUnits" | translate }}
              </div>
              <div *ngIf="property?.numUnits" class="column label-color2">
                {{ property?.numUnits }}
              </div>
              <div *ngIf="property?.rentalType" class="column label-color">
                {{ "detail.rentalType" | translate }}
              </div>
              <div *ngIf="property?.rentalType" class="column label-color2">
                {{ property?.rentalType }}
              </div>
              <div *ngIf="property?.retailStories" class="column label-color">
                {{ "detail.retailStories" | translate }}
              </div>
              <div *ngIf="property?.retailStories" class="column label-color2">
                {{ property?.retailStories }}
              </div>
              <div
                *ngIf="property?.apartmentStories"
                class="column label-color"
              >
                {{ "detail.apartmentStories" | translate }}
              </div>
              <div
                *ngIf="property?.apartmentStories"
                class="column label-color2"
              >
                {{ property?.apartmentStories }}
              </div>
              <div *ngIf="property?.parkingStories" class="column label-color">
                {{ "detail.parkingStories" | translate }}
              </div>
              <div *ngIf="property?.parkingStories" class="column label-color2">
                {{ property?.parkingStories }}
              </div>
              <div
                *ngIf="property?.furnishing?.displayName"
                class="column label-color"
              >
                {{ "detail.furnishing" | translate }}
              </div>
              <div
                *ngIf="property?.furnishing?.displayName"
                class="column label-color2"
              >
                {{ property?.furnishing?.displayName | langSelector }}
              </div>
              <div *ngIf="property?.rooms" class="column label-color">
                {{ "detail.rooms" | translate }}
              </div>
              <div *ngIf="property?.rooms" class="column label-color2">
                {{ property?.rooms }}
              </div>
              <div
                *ngIf="property?.mixedUse || property?.mixedUse == false"
                class="column label-color"
              >
                {{ "detail.mixedUse" | translate }}
              </div>
              <div
                *ngIf="property?.mixedUse || property?.mixedUse == false"
                class="column label-color2"
              >
                {{
                  property?.mixedUse
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div *ngIf="property?.totalShops" class="column label-color">
                {{ "detail.totalShops" | translate }}
              </div>
              <div *ngIf="property?.totalShops" class="column label-color2">
                {{ property?.totalShops }}
              </div>
              <div
                *ngIf="property?.totalOccupiedShops"
                class="column label-color"
              >
                {{ "detail.totalOccupiedShops" | translate }}
              </div>
              <div
                *ngIf="property?.totalOccupiedShops"
                class="column label-color2"
              >
                {{ property?.totalOccupiedShops }}
              </div>
              <div
                *ngIf="property?.totalAvailableShops"
                class="column label-color"
              >
                {{ "detail.totalAvailableShops" | translate }}
              </div>
              <div
                *ngIf="property?.totalAvailableShops"
                class="column label-color2"
              >
                {{ property?.totalAvailableShops }}
              </div>
              <div
                *ngIf="property?.waterMeter || property?.waterMeter == false"
                class="column label-color"
              >
                {{ "detail.waterMeter" | translate }}
              </div>
              <div
                *ngIf="property?.waterMeter || property?.waterMeter == false"
                class="column label-color2"
              >
                {{
                  property?.waterMeter
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="property?.gasMeter || property?.gasMeter == false"
                class="column label-color"
              >
                {{ "detail.gasMeter" | translate }}
              </div>
              <div
                *ngIf="property?.gasMeter || property?.gasMeter == false"
                class="column label-color2"
              >
                {{
                  property?.gasMeter
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.electricityMeter ||
                  property?.electricityMeter == false
                "
                class="column label-color"
              >
                {{ "detail.electricityMeter" | translate }}
              </div>
              <div
                *ngIf="
                  property?.electricityMeter ||
                  property?.electricityMeter == false
                "
                class="column label-color2"
              >
                {{
                  property?.electricityMeter
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  (property?.powerGenPrivateArea ||
                    property?.powerGenPrivateArea == false) &&
                  platformId == 'mx'
                "
                class="column label-color"
              >
                {{ "detail.powerGenPrivateArea" | translate }}
              </div>
              <div
                *ngIf="
                  (property?.powerGenPrivateArea ||
                    property?.powerGenPrivateArea == false) &&
                  platformId == 'mx'
                "
                class="column label-color2"
              >
                {{
                  property?.powerGenPrivateArea
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="property?.motorLobby || property?.motorLobby == false"
                class="column label-color"
              >
                {{ "detail.motorLobby" | translate }}
              </div>
              <div
                *ngIf="property?.motorLobby || property?.motorLobby == false"
                class="column label-color2"
              >
                {{
                  property?.motorLobby
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="property?.cctv || property?.cctv == false"
                class="column label-color"
              >
                {{ "detail.cctv" | translate }}
              </div>
              <div
                *ngIf="property?.cctv || property?.cctv == false"
                class="column label-color2"
              >
                {{
                  property?.cctv
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="property?.roofGarden || property?.roofGarden == false"
                class="column label-color"
              >
                {{ "detail.roofGarden" | translate }}
              </div>
              <div
                *ngIf="property?.roofGarden || property?.roofGarden == false"
                class="column label-color2"
              >
                {{
                  property?.roofGarden
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property.rentableArea &&
                  property.parkingSpaces &&
                  platformId == 'mx'
                "
                class="column label-color"
              >
                {{ "detail.parkingRatio" | translate }}
              </div>
              <div
                *ngIf="
                  property.rentableArea &&
                  property.parkingSpaces &&
                  platformId == 'mx'
                "
                class="column label-color2"
              >
                {{
                  property.rentableArea / property.parkingSpaces | localNumber
                }}
              </div>
              <div *ngIf="property.elevators" class="column label-color">
                {{ "detail.elevators" | translate }}
              </div>
              <div *ngIf="property.elevators" class="column label-color2">
                {{ property.elevators }}
              </div>
              <div
                *ngIf="
                  property?.managerOnSite || property?.managerOnSite == false
                "
                class="column label-color"
              >
                {{ "detail.managerOnSite" | translate }}
              </div>
              <div
                *ngIf="
                  property?.managerOnSite || property?.managerOnSite == false
                "
                class="column label-color2"
              >
                {{
                  property?.managerOnSite
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="
                  property?.hasElevators || property?.hasElevators == false
                "
                class="column label-color hasElevators"
              >
                {{ "detail.elevators" | translate }}
              </div>
              <div
                *ngIf="
                  property?.hasElevators || property?.hasElevators == false
                "
                class="column label-color2 hasElevators"
              >
                {{
                  property?.hasElevators
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="
                  property?.airConditioning ||
                  property?.airConditioning == false
                "
                class="column label-color airConditioning"
              >
                {{ "detail.airConditioning" | translate }}
              </div>
              <div
                *ngIf="
                  property?.airConditioning ||
                  property?.airConditioning == false
                "
                class="column label-color2 airConditioning"
              >
                {{
                  property?.airConditioning
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="
                  property?.fitnessCenter || property?.fitnessCenter == false
                "
                class="column label-color fitnessCenter"
              >
                {{ "detail.FitnessCenter" | translate }}
              </div>
              <div
                *ngIf="
                  property?.fitnessCenter || property?.fitnessCenter == false
                "
                class="column label-color2 fitnessCenter"
              >
                {{
                  property?.fitnessCenter
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="property?.clubhouse || property?.clubhouse == false"
                class="column label-color"
              >
                {{ "detail.Clubhouse" | translate }}
              </div>
              <div
                *ngIf="property?.clubhouse || property?.clubhouse == false"
                class="column label-color2"
              >
                {{
                  property?.clubhouse
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="
                  property?.wifiInCommonAreas ||
                  property?.wifiInCommonAreas == false
                "
                class="column label-color"
              >
                {{ "detail.wiFiInCommonAreas" | translate }}
              </div>
              <div
                *ngIf="
                  property?.wifiInCommonAreas ||
                  property?.wifiInCommonAreas == false
                "
                class="column label-color2"
              >
                {{
                  property?.wifiInCommonAreas
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="
                  property?.onlineResidentServices ||
                  property?.onlineResidentServices == false
                "
                class="column label-color"
              >
                {{ "detail.onlineResidentServices" | translate }}
              </div>
              <div
                *ngIf="
                  property?.onlineResidentServices ||
                  property?.onlineResidentServices == false
                "
                class="column label-color2"
              >
                {{
                  property?.retailOnSite
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="property?.playground || property?.playground == false"
                class="column label-color playground"
              >
                {{ "detail.playground" | translate }}
              </div>
              <div
                *ngIf="property?.playground || property?.playground == false"
                class="column label-color2 playground"
              >
                {{
                  property?.playground
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="property?.dogWalker || property?.dogWalker == false"
                class="column label-color"
              >
                {{ "detail.dogWalker" | translate }}
              </div>
              <div
                *ngIf="property?.dogWalker || property?.dogWalker == false"
                class="column label-color2"
              >
                {{
                  property?.dogWalker
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.sharedKitchen || property?.sharedKitchen == false
                "
                class="column label-color"
              >
                {{ "detail.sharedKitchen" | translate }}
              </div>
              <div
                *ngIf="
                  property?.sharedKitchen || property?.sharedKitchen == false
                "
                class="column label-color2"
              >
                {{
                  property?.sharedKitchen
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.sharedLaundry || property?.sharedLaundry == false
                "
                class="column label-color"
              >
                {{ "detail.sharedLaundry" | translate }}
              </div>
              <div
                *ngIf="
                  property?.sharedLaundry || property?.sharedLaundry == false
                "
                class="column label-color2"
              >
                {{
                  property?.sharedLaundry
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="property?.partyRoom || property?.partyRoom == false"
                class="column label-color"
              >
                {{ "detail.partyRoom" | translate }}
              </div>
              <div
                *ngIf="property?.partyRoom || property?.partyRoom == false"
                class="column label-color2"
              >
                {{
                  property?.partyRoom
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="property?.dishwasher || property?.dishwasher == false"
                class="column label-color"
              >
                {{ "detail.dishwasher" | translate }}
              </div>
              <div
                *ngIf="property?.dishwasher || property?.dishwasher == false"
                class="column label-color2"
              >
                {{
                  property?.dishwasher
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="property?.disposal || property?.disposal == false"
                class="column label-color"
              >
                {{ "detail.disposal" | translate }}
              </div>
              <div
                *ngIf="property?.disposal || property?.disposal == false"
                class="column label-color2"
              >
                {{
                  property?.disposal
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="
                  property?.washerDryerHookup ||
                  property?.washerDryerHookup == false
                "
                class="column label-color"
              >
                {{ "detail.WasherDryerHookup" | translate }}
              </div>
              <div
                *ngIf="
                  property?.washerDryerHookup ||
                  property?.washerDryerHookup == false
                "
                class="column label-color2"
              >
                {{
                  property?.washerDryerHookup
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.walkInClosets || property?.walkInClosets == false
                "
                class="column label-color"
              >
                {{ "detail.WalkInClosets" | translate }}
              </div>
              <div
                *ngIf="
                  property?.walkInClosets || property?.walkInClosets == false
                "
                class="column label-color2"
              >
                {{
                  property?.walkInClosets
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.graniteCountertops ||
                  property?.graniteCountertops == false
                "
                class="column label-color"
              >
                {{ "detail.graniteCountertops" | translate }}
              </div>
              <div
                *ngIf="
                  property?.graniteCountertops ||
                  property?.graniteCountertops == false
                "
                class="column label-color2"
              >
                {{
                  property?.graniteCountertops
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.highSpeedInternetAccess ||
                  property?.highSpeedInternetAccess == false
                "
                class="column label-color"
              >
                {{ "detail.highSpeedInternetAccess" | translate }}
              </div>
              <div
                *ngIf="
                  property?.highSpeedInternetAccess ||
                  property?.highSpeedInternetAccess == false
                "
                class="column label-color2"
              >
                {{
                  property?.highSpeedInternetAccess
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="property?.wifi || property?.wifi == false"
                class="column label-color"
              >
                {{ "detail.WiFi" | translate }}
              </div>
              <div
                *ngIf="property?.wifi || property?.wifi == false"
                class="column label-color2"
              >
                {{
                  property?.wifi
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.hardwoodFloors || property?.hardwoodFloors == false
                "
                class="column label-color"
              >
                {{ "detail.hardwoodFloors" | translate }}
              </div>
              <div
                *ngIf="
                  property?.hardwoodFloors ||
                  property?.wifhardwoodFloorsi == false
                "
                class="column label-color2"
              >
                {{
                  property?.hardwoodFloors
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.stainlessSteelAppliances ||
                  property?.stainlessSteelAppliances == false
                "
                class="column label-color"
              >
                {{ "detail.stainlessSteelAppliances" | translate }}
              </div>
              <div
                *ngIf="
                  property?.stainlessSteelAppliances ||
                  property?.stainlessSteelAppliances == false
                "
                class="column label-color2"
              >
                {{
                  property?.stainlessSteelAppliances
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="property?.ceilingFans || property?.ceilingFans == false"
                class="column label-color ceilingFans"
              >
                {{ "detail.ceilingFans" | translate }}
              </div>
              <div
                *ngIf="property?.ceilingFans || property?.ceilingFans == false"
                class="column label-color2 ceilingFans"
              >
                {{
                  property?.ceilingFans
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="
                  property?.privatePatio || property?.privatePatio == false
                "
                class="column label-color"
              >
                {{ "detail.privatePatio" | translate }}
              </div>
              <div
                *ngIf="
                  property?.privatePatio || property?.privatePatio == false
                "
                class="column label-color2"
              >
                {{
                  property?.privatePatio
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.cleaningServices ||
                  property?.cleaningServices == false
                "
                class="column label-color"
              >
                {{ "detail.cleaningServices" | translate }}
              </div>
              <div
                *ngIf="
                  property?.cleaningServices ||
                  property?.cleaningServices == false
                "
                class="column label-color2"
              >
                {{
                  property?.cleaningServices
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="property?.petFriendly || property?.petFriendly == false"
                class="column label-color petFriendly"
              >
                {{ "detail.petFriendly" | translate }}
              </div>
              <div
                *ngIf="property?.petFriendly || property?.petFriendly == false"
                class="column label-color2 petFriendly"
              >
                {{
                  property?.petFriendly
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <!-- <div
                *ngIf="
                  property?.manufacturing || property?.manufacturing == false
                "
                class="column label-color "
              >
                {{ "detail.manufacturing" | translate }}
              </div>
              <div
                *ngIf="
                  property?.manufacturing || property?.manufacturing == false
                "
                class="column label-color2"
              >
                {{
                  property?.manufacturing
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div> -->
              <div
                *ngIf="
                  property?.blockConstruction ||
                  property?.blockConstruction == false
                "
                class="column label-color "
              >
                {{ "detail.blockConstruction" | translate }}
              </div>
              <div
                *ngIf="
                  property?.blockConstruction ||
                  property?.blockConstruction == false
                "
                class="column label-color2"
              >
                {{
                  property?.blockConstruction
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.steelConstruction ||
                  property?.steelConstruction == false
                "
                class="column label-color "
              >
                {{ "detail.steelConstruction" | translate }}
              </div>
              <div
                *ngIf="
                  property?.steelConstruction ||
                  property?.steelConstruction == false
                "
                class="column label-color2"
              >
                {{
                  property?.steelConstruction
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="
                  property?.blockAndSteelConstruction ||
                  property?.blockAndSteelConstruction == false
                "
                class="column label-color "
              >
                {{ "detail.blockAndSteelConstruction" | translate }}
              </div>
              <div
                *ngIf="
                  property?.blockAndSteelConstruction ||
                  property?.blockAndSteelConstruction == false
                "
                class="column label-color2"
              >
                {{
                  property?.blockAndSteelConstruction
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>

              <div
                *ngIf="property?.industrialParkingSpaces"
                class="column label-color "
              >
                {{ "detail.industrialParkingSpaces" | translate }}
              </div>
              <div
                *ngIf="property?.industrialParkingSpaces"
                class="column label-color2"
              >
                {{ property?.industrialParkingSpaces | localNumber: "1.0-0" }}
              </div>
              <div *ngIf="property?.baySize" class="column label-color ">
                {{ "detail.baySize" | translate }}
              </div>
              <div *ngIf="property?.baySize" class="column label-color2">
                {{ property?.baySize }}
              </div>
              <div *ngIf="property?.dockRatio" class="column label-color ">
                {{ "detail.dockRatio" | translate }}
              </div>
              <div *ngIf="property?.dockRatio" class="column label-color2">
                {{ property?.dockRatio }}
              </div>
              <div
                *ngIf="property?.propertyEfficiency && platformId == 'mx'"
                class="column label-color "
              >
                {{ "detail.propertyEfficiency" | translate }}
              </div>
              <div
                *ngIf="property?.propertyEfficiency && platformId == 'mx'"
                class="column label-color2"
              >
                {{ property?.propertyEfficiency }}%
              </div>
              <div
                *ngIf="property?.monthlyManagementFeesUSD && platformId == 'mx'"
                class="column label-color "
              >
                {{ "detail.monthlyManagementFeesUSD" | translate }}
              </div>
              <div
                *ngIf="property?.monthlyManagementFeesUSD && platformId == 'mx'"
                class="column label-color2"
              >
                {{ property?.monthlyManagementFeesUSD | localNumber }}
              </div>
              <div
                *ngIf="property?.monthlyManagementFeesUSD && platformId == 'mx'"
                class="column label-color "
              >
                {{ "detail.monthlyManagementFeesUSDFt" | translate }}
              </div>
              <div
                *ngIf="property?.monthlyManagementFeesUSD && platformId == 'mx'"
                class="column label-color2"
              >
                {{
                  (property?.monthlyManagementFeesUSD / 10.76391) * 12
                    | localNumber
                }}
              </div>
            </div>
            <div
              class="col-md-12 vacancy-button mt-3"
              *ngIf="vacancySpot.length > 0 && vacantAreaList.length > 0"
            >
              <button
                class="form-control"
                (click)="openVacancy(vacancySpot[0])"
                mat-flat-button
                color="accent"
                style="font-weight: bold;"
              >
                {{ "detail.viewVacancy" | translate }}
              </button>
            </div>

            <div
              class="col-md-12 vacancy-button mt-3"
              *ngIf="
                occupancies.length > 0 && property?.buildingType.id == 3001
              "
            >
              <button
                class="form-control"
                (click)="openChartPopup(chartModal)"
                mat-flat-button
                color="accent"
                style="font-weight: bold;"
              >
                {{ "detail.occupancyHistory" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 ">
        <aside>
          <div *ngIf="listingCards.length > 0">
            <ngx-slick-carousel #slickListing>
              <div ngxSlickItem *ngFor="let listing of listingCards">
                <featured-listing-card
                  class="slide"
                  [hideLogo]="true"
                  [listing]="listing"
                  [latamCountry]="null"
                ></featured-listing-card>
              </div>
            </ngx-slick-carousel>
            <button
              *ngIf="listingCards.length > 1"
              (click)="slickPrev(slickListing)"
              mat-basic-button
              #videoPrev
              aria-label="left arrow"
              class="news-leftLs btnCard chevron_left-listing"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button
              *ngIf="listingCards.length > 1"
              (click)="slickNext(slickListing)"
              mat-basic-button
              #videoNext
              aria-label="right arrow"
              class="news-rightLs btnCard chevron_right-listing"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
          <div
            id="findBannerImage"
            (click)="openBannerImage()"
            *ngIf="listingCards.length == 0"
          >
            <img class="banner-img" [src]="findBannerImage()" alt="" />
          </div>
          <div *ngIf="articles && articles.content.length > 0">
            <ngx-slick-carousel #slickResource>
              <div
                ngxSlickItem
                *ngFor="let article of articles.content"
                class="slide"
              >
                <div style="text-align: center;">
                  <span class="title" style="text-decoration: underline;">{{
                    "detail.featuredResource" | translate
                  }}</span>
                </div>
                <div
                  class="mat-elevation-z8 mt-2 resource"
                  style="cursor: pointer;"
                  (click)="openNews(article.id, article)"
                >
                  <img
                    class="w-100 img-topic aspect-4-3"
                    alt="test"
                    title="test"
                    [src]="
                      article?.headerImage?.fullPath
                        ? headerImageNews(article?.headerImage?.fullPath)
                        : ''
                    "
                    default="assets/img/defaultImage.png"
                  />
                  <div class="m-2 mt-3 pb-2">
                    <span style="font-weight: 700; color: #515050;"></span>
                    {{ article.headline | langSelector }}
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
            <button
              *ngIf="articles.content.length > 1"
              [ngClass]="{
                'chevron_left-resource-banner': listingCards.length == 0
              }"
              (click)="slickPrev(slickResource)"
              mat-basic-button
              #videoPrev
              aria-label="left arrow"
              class="news-leftLs btnCard chevron_left-resource"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button
              *ngIf="articles.content.length > 1"
              [ngClass]="{
                'chevron_right-resource-banner': listingCards.length == 0
              }"
              (click)="slickNext(slickResource)"
              mat-basic-button
              #videoNext
              aria-label="right arrow"
              class="news-rightLs btnCard chevron_right-resource"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
          <div
            id="findSpotResourceBanner"
            (click)="openSpotResourceBannerLink()"
            *ngIf="articles && articles.content.length == 0"
          >
            <img class="banner-img" [src]="findSpotResourceBanner()" alt="" />
          </div>
        </aside>
      </div>
    </div>
    <div class="col-md-12 mt-4 ">
      <div
        class="col-md-12 p-0"
        *ngIf="marketRentList.length > 0 && validateMarketRent.length > 0"
      >
        <div class="col-md-12">
          <span class="title col-md-12 ">{{
            "detail.historicVacancyMarketRents" | translate
          }}</span>
        </div>
        <div class="col-md-12 title-bar mt-3">
          <mat-button-toggle-group
            class="toggle-group"
            appearance="legacy"
            name="fontStyle"
            aria-label="Font Style"
          >
            <mat-button-toggle
              [ngClass]="{ 'active-toggle': typeBar === 1 }"
              class="toogle-btn"
              (click)="setHistoricBar(1); setOccupancyGraph()"
              value="1"
            >
              {{ "detail.currentProperty" | translate }}</mat-button-toggle
            >
            <mat-button-toggle
              [ngClass]="{ 'active-toggle': typeBar === 2 }"
              class="toogle-btn"
              (click)="setHistoricBar(2); setOccupancyGraph()"
              value="2"
            >
              {{ property?.region?.name | langSelector }}
              {{ property?.classType.name }}</mat-button-toggle
            >
            <mat-button-toggle
              [ngClass]="{ 'active-toggle': typeBar === 3 }"
              class="toogle-btn"
              (click)="setHistoricBar(3)"
              value="3"
            >
              {{ "detail.combined" | translate }}</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
        <div class="col-md-12" id="chart">
          <div class="col-md-12 mt-3">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [yaxis]="chartOptions.yaxis"
              [labels]="chartOptions.labels"
              [title]="chartOptions.title"
              [theme]="chartOptions.theme"
              [stroke]="chartOptions.stroke"
              [colors]="chartOptions.colors"
            ></apx-chart>
          </div>
        </div>
        <!-- <div class="col-md-12" id="chart2">
          <div class="col-md-12 mt-3">
            <apx-chart
              [series]="chartOptionsOccupancy.series"
              [chart]="chartOptionsOccupancy.chart"
              [yaxis]="chartOptionsOccupancy.yaxis"
              [labels]="chartOptionsOccupancy.labels"
              [title]="chartOptionsOccupancy.title"
              [theme]="chartOptionsOccupancy.theme"
              [stroke]="chartOptionsOccupancy.stroke"
              [colors]="chartOptionsOccupancy.colors"
            ></apx-chart>
          </div>
        </div> -->

        <!-- 
<div class="modal fade" id="chartModal" #chartModal tabindex="-1" aria-labelledby="chartModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="chartModalLabel">{{ "detail.occupancyHistory" | translate }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="col-md-12 mt-3">
          <apx-chart
            [series]="chartOptionsOccupancy.series"
            [chart]="chartOptionsOccupancy.chart"
            [yaxis]="chartOptionsOccupancy.yaxis"
            [labels]="chartOptionsOccupancy.labels"
            [title]="chartOptionsOccupancy.title"
            [theme]="chartOptionsOccupancy.theme"
            [stroke]="chartOptionsOccupancy.stroke"
            [colors]="chartOptionsOccupancy.colors"
          ></apx-chart>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> -->
      </div>
      <div>
        <div class="col-md-12">
          <div
            *ngIf="industryPercentagesList.industryPercentages.length > 0"
            class="col-md-12 mt-3"
          >
            <span class="title col-md-12 ">{{
              "detail.tenantsIndustry" | translate
            }}</span>
          </div>

          <div
            *ngIf="industryPercentagesList.industryPercentages.length > 0"
            class="col-md-12 pie-content"
            id="chart"
          >
            <div class="col-md-6 mt-3">
              <apx-chart
                [series]="chartOptionsPie.series"
                [chart]="chartOptionsPie.chart"
                [labels]="chartOptionsPie.labels"
                [responsive]="chartOptionsPie.responsive"
                [colors]="chartOptionsPie.colors"
              ></apx-chart>
            </div>
            <button
              *ngIf="tenantAreas.length > 0"
              mat-flat-button
              color="accent"
              style="    position: absolute;
          margin: 16px;
          left: 36%;
          bottom: 1;"
              (click)="resetTenantIndustry()"
            >
              {{ "detail.all" | translate }}
            </button>
            <div
              class="col-md-6 mt-2"
              *ngIf="industryPercentagesList.tenantAreas.length > 0"
              style="padding: 0px;"
            >
              <div class="col-md-12 key-facts">
                <span>{{ "detail.topTenants" | translate }}</span>
              </div>
              <div class=" table-tenant">
                <table class="table">
                  <thead class="head-vacant">
                    <tr>
                      <th>{{ "detail.rank" | translate }}</th>
                      <th>
                        {{ "detail.tenant" | translate }}
                      </th>
                      <th>{{ "detail.areaOccupied" | translate }}</th>
                      <th>{{ "detail.industry" | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let tenant of tenantAreas.length > 0
                          ? tenantAreas
                          : industryPercentagesList.tenantAreas;
                        let i = index
                      "
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ tenant.tenantName }}</td>
                      <td>{{ tenant.area | localNumber }}</td>
                      <td>{{ tenant.categoryName | langSelector }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="pointOfInterest.length > 0">
          <div class="col-md-12">
            <div class="col-md-12 key-facts">
              <span>{{ "detail.pointsOfInterest" | translate }}</span>
            </div>
            <div class="border-table points-table">
              <table class="table" *ngFor="let point of pointOfInterest">
                <thead class="head-vacant">
                  <tr>
                    <th>{{ point[0].type.displayName | langSelector }}</th>
                    <th>{{ "detail.driveTime" | translate }}</th>
                    <th>{{ "detail.distance" | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let rowPoint of point; let i = index">
                    <td>{{ rowPoint.name }}</td>
                    <td>{{ rowPoint.driveTime }} min</td>
                    <td>{{ rowPoint.distance }} km</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="unitsMix && unitsMix.length > 0"
        class="col-md-12 unitmixtable"
      >
        <div class="col-md-12 key-facts">
          <span>{{ "detail.unitMix" | translate }}</span>
        </div>
        <div class="table-unitmix">
          <table class="table">
            <thead class="head-vacant title-row unitmixheaders">
              <tr>
                <th>{{ "detail.floorPlans" | translate }}</th>
                <th style="max-width: 145px;">
                  {{ "detail.numberOfUnits" | translate }}
                </th>
                <th style="min-width: 95px;">
                  {{ "detail.area" | translate }} (m²)
                </th>
                <th *ngIf="unitMixColVisibility.get('cqAvgMonthlyRent')">
                  {{ "detail.monthlyRent" | translate }}
                </th>
                <th *ngIf="unitMixColVisibility.get('monthlyrentsm')">
                  {{ "detail.monthlyRentm2" | translate }}
                </th>
                <th
                  style="width: 171px;"
                  *ngIf="unitMixColVisibility.get('cqAvgMonthlyRentFurnished')"
                >
                  {{ "detail.monthlyRentf" | translate }}
                </th>
                <th
                  style="width: 196px;"
                  *ngIf="unitMixColVisibility.get('monthlyrentsmf')"
                >
                  {{ "detail.monthlyRentm2f" | translate }}
                </th>
                <th *ngIf="unitMixColVisibility.get('detailsName')">
                  {{ "detail.details" | translate }}
                </th>
              </tr>
            </thead>
            <tbody class="border-table title-row unitmixtablerows">
              <tr
                *ngFor="
                  let um of unitsMix && unitsMix.length > 0 ? unitsMix : [];
                  let i = index
                "
              >
                <td
                  style="text-align: right;"
                  *ngIf="unitMixColVisibility.get('floorPlan')"
                >
                  {{ um.floorPlan }}
                </td>
                <td *ngIf="unitMixColVisibility.get('numberOfUnits')">
                  {{ um.numberOfUnits }}
                </td>
                <td *ngIf="unitMixColVisibility.get('avgArea')">
                  {{ um.avgArea }}
                </td>
                <td *ngIf="unitMixColVisibility.get('cqAvgMonthlyRent')">
                  {{ um.cqAvgMonthlyRent }}
                </td>
                <td *ngIf="unitMixColVisibility.get('cqAvgMonthlyRent')">
                  {{ um.monthlyrentsm }}
                </td>
                <td
                  *ngIf="unitMixColVisibility.get('cqAvgMonthlyRentFurnished')"
                >
                  {{ um.cqAvgMonthlyRentFurnished }}
                </td>
                <td
                  *ngIf="unitMixColVisibility.get('cqAvgMonthlyRentFurnished')"
                >
                  {{ um.monthlyrentsmf }}
                </td>
                <td *ngIf="unitMixColVisibility.get('detailsName')">
                  {{ um.detailsName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="col-md-12 d-flex"
        style="padding: 0px;"
        *ngIf="owners.length > 0 || developers.length > 0"
      >
        <div class="col-md-12">
          <div class="col-md-12 key-facts mt-2">
            <span>{{ "detail.ownersDevelopers" | translate }}</span>
          </div>
          <div
            class="d-flex border-table"
            [ngClass]="{ 'd-block': isMobile }"
            style="    margin-top: -1px;"
          >
            <div class="column label-color owner-dev-columns">
              {{ "detail.owners" | translate }}
            </div>
            <div
              class="column label-color2 align-dev-own"
              style="border: 1px solid #e0e2e7;"
            >
              <span
                class="d-flex"
                (click)="openCompanySearch(owner)"
                *ngFor="let owner of owners.slice(0, 10); let i = index"
              >
                <p>{{ i + 1 }}.</p>
                <p>{{ owner }}</p>
              </span>
            </div>
            <div class="column label-color owner-dev-columns">
              {{ "detail.developer" | translate }}
            </div>
            <div
              class="column label-color2 align-dev-own"
              style="border: 1px solid #e0e2e7;"
            >
              <span
                class="d-flex"
                (click)="openCompanySearch(developer)"
                *ngFor="let developer of developers.slice(0, 10); let i = index"
              >
                <p>{{ i + 1 }}.</p>
                <p>{{ developer }}</p>
              </span>
            </div>
          </div>
        </div>

        <!--   <div class="col-md-6">
          <div class="col-md-12 key-facts mt-2">
            <span>{{ "detail.ownersDevelopers" | translate }}</span>
          </div>
          <div class="d-flex key-facts-columns border-table">
          
          </div>
        </div> -->
      </div>
      <div class="col-md-12 mt-3" *ngIf="rentRolls.length > 0">
        <div class="col-md-12">
          <button
            class="form-control"
            (click)="openTenantsOwners()"
            mat-flat-button
            color="accent"
            style="font-weight: bold;width: 300px;float: inline-end;"
          >
            {{ "detail.viewTenantsOwners" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-5">
      <div class="col-md-12 content-title">
        <span class="title col-md-12 ">{{
          "detail.availabilityProperties" | translate
        }}</span>
        <br />
        <span
          class="col-md-12 "
          style="color: #a9a9a9;
        font-weight: 600;
        font-size: 16px;"
          >{{ "detail.propertiesSaleRent" | translate }}</span
        >
      </div>
      <div class="propComparabledbg">
        <featured-listings
          class="mt-2 propertiescomparableFtlistings"
          [listings]="propertiesComparable"
          [hideLogo]="true"
        >
        </featured-listings>
      </div>
    </div>

    <div class="col-md-12" *ngIf="buildings.length > 0">
      <div class="col-md-12 content-title">
        <span class="title col-md-12 ">{{
          "detail.comparableProperties" | translate
        }}</span>
      </div>
      <featured-listings-detail
        class="buildingftliddtaildb"
        [buildings]="buildings"
        [hideLogo]="true"
        [detail]="true"
      >
      </featured-listings-detail>
      <!--  <div
        class="col-md-12"
        style="text-align: center;
        margin-bottom: 60px;margin-top: -34px;"
      >
        <button mat-stroked-button color="primary">
          {{ "detail.seeMore" | translate }}
        </button>
      </div> -->
    </div>
    <div class="col-md-12 mt-2">
      <p
        translate="products.{{
          currentCode
        }}.products.features-card.marketAnalytics.findMore"
        class="find-more siila-grey-dark"
      ></p>
      <div class="market-analytics-card mt-5">
        <ng-container *ngFor="let card of marketCardList">
          <app-info-card
            (click)="openInfoCard(card)"
            [icon]="card.src"
            [iconAltTitle]="card.iconAltTitle"
            [title]="card.title | langSelector"
            [text]="card.text | langSelector"
            [actionTxt]="card.actionText | langSelector"
            [background]="card.background"
          ></app-info-card>
        </ng-container>
      </div>
      <!-- <div class="col-md-12">
        <span class="title col-md-12 ">{{
          "detail.learnMoreAboutProducts" | translate
        }}</span>
      </div>

      <div
        class="col-md-12 mt-3 content-columns"
        style="margin: 0 auto;
        width: 90%;"
      >
        <div class="col-md-4">
          <div
            class="mat-elevation-z8 mt-2"
            style="cursor: pointer;
                      margin: 0 auto;"
          >
            <img
              class="w-100 aspect-14-9"
              alt="test"
              title="test"
              src=""
              default="assets/img/sbi.jpg"
            />
            <div class="m-2 mt-3 pb-2 about-products">
              <div>
                <strong>{{ "detail.sbi" | translate }}</strong>
              </div>
              <div>
                <span>{{ "detail.siilaBrasilIndex" | translate }}</span>
              </div>
              <div>
                <button
                  routerLink="/products/102/siila-brasil-index"
                  mat-flat-button
                  color="accent"
                >
                  {{ "detail.learnMore" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="mat-elevation-z8 mt-2"
            style="cursor: pointer;
                      margin: 0 auto;"
          >
            <img
              class="w-100 aspect-14-9"
              alt="test"
              title="test"
              src=""
              default="assets/img/FII_Data.jpg"
            />
            <div class="m-2 mt-3 pb-2 about-products">
              <div>
                <strong>{{ "detail.fiiDataProfessional" | translate }}</strong>
              </div>
              <div>
                <span>{{ "detail.reitsAnalytics" | translate }}</span>
              </div>
              <div>
                <button
                  routerLink="/fii-data-pro"
                  mat-flat-button
                  color="accent"
                >
                  {{ "detail.getStarted" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div
            class="mat-elevation-z8 mt-2"
            style="cursor: pointer;
                      margin: 0 auto;"
          >
            <img
              class="w-100 aspect-14-9"
              alt="test"
              title="test"
              src=""
              default="assets/img/academy.jpg"
            />
            <div class="m-2 mt-3 pb-2 about-products">
              <div>
                <strong>{{ "detail.academy" | translate }}</strong>
              </div>
              <div>
                <span>{{ "detail.rigurousEngaging" | translate }}</span>
              </div>
              <div>
                <button routerLink="/academy" mat-flat-button color="accent">
                  {{ "detail.getStarted" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>

<!-- <div
  class="modal fade"
  id="chartModal"
  #chartModal
  tabindex="-1"
  aria-labelledby="chartModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered"
    style="max-width: 350px;"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="chartModalLabel">
          {{ "detail.occupancyHistoryTitle" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="col-md-12 key-facts">
          <span>{{ "detail.occupancy" | translate }}</span>
        </div>
        <div class=" table-tenant">
          <table class="table">
            <thead class="head-vacant">
              <tr>
                <th>
                  {{ "detail.occDate" | translate }}
                </th>
                <th>{{ "detail.occupancy" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let occ of occupancies; let i = index">
                <td>
                  {{
                    browserLang == "pt"
                      ? (occ.dateSurveyed | date: "dd/MM/yyyy")
                      : (occ.dateSurveyed | date: "MM/dd/yyyy")
                  }}
                </td>
                <td>
                  <span *ngIf="occ.occupancy >= 0"
                    >{{ occ.occupancy | localNumber }} %</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div> -->

<!-- NgbModal Template -->
<ng-template #chartModal let-modal>
  <div class="custom-modal custom-modal-centered">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "detail.occupancyHistoryTitle" | translate }}
      </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="col-md-12 key-facts">
        <span>{{ "detail.occupancy" | translate }}</span>
      </div>
      <div class="table-tenant">
        <table class="table">
          <thead class="head-vacant" style="text-align: center;">
            <tr>
              <th>{{ "detail.occDate" | translate }}</th>
              <th>{{ "detail.occupancy" | translate }}</th>
            </tr>
          </thead>
          <tbody style="text-align: center;">
            <tr *ngFor="let occ of occupancies; let i = index">
              <td>
                {{
                  browserLang == "pt"
                    ? (occ.dateSurveyed | date: "dd/MM/yyyy")
                    : (occ.dateSurveyed | date: "MM/dd/yyyy")
                }}
              </td>
              <td>
                <span *ngIf="occ.occupancy >= 0"
                  >{{ occ.occupancy | localNumber }} %</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close()">
        Close
      </button>
    </div>
  </div>
</ng-template>
