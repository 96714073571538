<div class="table-container">
  <table>
    <thead class="txnthead">
      <tr>
        <th *ngFor="let column of columnDefs">
          {{ column.header | translate }}
        </th>
      </tr>
    </thead>
    <tbody class="txnbody">
      <tr *ngFor="let transaction of transactions">
        <td
          *ngFor="let column of columnDefs"
          [ngClass]="{
            'truncate-cell': column.truncate,
            'truncate-width': column.truncate && column.maxWidth
          }"
          [matTooltip]="column.hover ? transaction[column.key] : ''"
          matTooltipPosition="after"
          matTooltipClass="large-tooltip"
          [innerHTML]="transaction[column.key]"
        ></td>
      </tr>
    </tbody>
  </table>
</div>
