<div class="col-md-12 content">
  <div class=" img1">
    <img
      [src]="photos.length > 0 ? getImage(photos[0].image.id) : ''"
      default="assets/img/defaultImage.png"
      alt="img1"
    />
  </div>
  <div class="img2-content">
    <div class="col-md-12" style="padding: 0px;">
      <div class="content-btn">
        <button class="form-control" (click)="showMap()">
          {{ "detail.map" | translate }}
        </button>
        <button class="form-control" (click)="showSatellite()">
          {{ "detail.satellite" | translate }}
        </button>
        <button class="form-control" (click)="toggleKml()">
          {{
            !showKml
              ? ("detail.showRegion" | translate)
              : ("detail.hideRegion" | translate)
          }}
        </button>
      </div>

      <agm-map
        #duploMap
        class="map"
        [latitude]="latitude"
        [longitude]="longitude"
        [zoom]="zoom"
        [mapTypeId]="mapTypeIds"
        [usePanning]="true"
        [fullscreenControl]="true"
        id="map"
      >
        <agm-marker
          [latitude]="latitude"
          [longitude]="longitude"
          [agmFitBounds]="true"
        >
        </agm-marker>
        <div *ngIf="showKml">
          <agm-kml-layer
            *ngFor="let kml of kmsList"
            [url]="kml.kmlUrl"
            [suppressInfoWindows]="true"
            (layerClick)="onKmlClick($event)"
          ></agm-kml-layer>
        </div>
      </agm-map>
      <div
        *ngIf="showCustomPopup"
        class="custom-popup"
        id="customPanel"
        style="font-size: 11px;padding-bottom: 0px;"
        [ngStyle]="{
          position: 'absolute',
          top: popupY + 'px',
          left: popupX + 'px',
          transform: 'translate(-50%, -100%)',
          background: 'white',
          padding: '5px',
          border: '1px solid black',
          borderRadius: '5px',
          zIndex: '2147483647'
        }"
      >
        <!-- Custom content goes here -->
        <p>{{ popupContent }}</p>
      </div>
    </div>
    <div
      style="margin-top: 30px;background-color: #f2f2f2; width: 96%; text-align: center;"
      class="col-md-12"
    >
      <span
        *ngIf="photos.length > 1"
        (click)="openPhotos()"
        class="form-control see-all"
      >
        {{ "detail.seeAll" | translate }}
      </span>
      <img
        *ngIf="photoValidation"
        [src]="getImage(photos[1].image.id)"
        default="assets/img/defaultImage.png"
        alt="img3"
      />
      <div *ngIf="!photoValidation" style="width: 100%;height: 296px;">
        <img
          style="width: 70px;height: 70px;margin-top: 24%;"
          src="assets/featuresIcons/promotedCard.png"
          alt=""
        />

        <br /><br />
        <span style="font-size: 20px;color: gray;">{{
          "detail.noImage" | translate
        }}</span>
      </div>
    </div>
  </div>
</div>
