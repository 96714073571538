import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild
} from "@angular/core";
import { TransactionsService } from "../../services/transactions.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../../services/property.services";
import { zip } from "rxjs";
import { DeviceRegistrationService } from "../../../core/services/device-registration.service";
import { AccountService, NewsService } from "../../../core";
import { MatDialog } from "@angular/material/dialog";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ImageGalleryComponent } from "../image-gallery/image-gallery.component";
import { environment } from "../../../../environments/environment";
import { NewsSearch } from "../../models/news-search.model";
import { NewsNavigateService } from "../../services/news.navigate.service";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { ProductService } from "../../../core/services/products.service";
import { ProductItem } from "../../../core/models/products.model";
import { I18nService } from "../../services";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexMarkers,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
import { title } from "process";
import { DatePipe } from "@angular/common";
import { apexObj } from "../../utils/params";
import { LocalNumberPipe } from "../../directives";
import { LocalNumberPipeSpot } from "../../pipes/number-pipe";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  tooltip: ApexTooltip;
  colors: string[];
  markers: ApexMarkers;
  fill: ApexFill;
};
const monthNamesMap = {
  Jan: "Jan",
  Feb: "Fev",
  Mar: "Mar",
  Apr: "Abr",
  May: "Mai",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Set",
  Oct: "Out",
  Nov: "Nov",
  Dec: "Dez"
};

@Component({
  selector: "app-detail-transactions",
  templateUrl: "./detail-transactions.component.html",
  styleUrls: ["./detail-transactions.component.scss"]
})
export class DetailTransactionsComponent implements OnInit {
  @ViewChild("chart") chartElement: ElementRef;
  transaction: any;
  id;
  showDetail = false;
  photos: any[] = [];
  registry: any[] = [];
  plan: any[] = [];
  documents: any[] = [];
  isAdmin = false;
  dialogRef: any;
  isMobile: boolean = false;
  listingCards = [];
  articles: NewsSearch;
  browserLang;
  platformId = environment.platformId;
  property;
  marketCardList = [];
  marketImgCard: string[];
  products: ProductItem[];
  currentCode: string;
  typeOpt = 1;
  validateBrokerList = false;
  validateBomaList = false;
  hideTrxAnalysis = true;
  saleCapRate: any;
  public chartOptions: Partial<ChartOptions>;
  typeChar = 1;
  leaseData: any;
  public chartOptionsLease: Partial<ChartOptions>;
  markerCount = 0;
  showPhone1 = false;
  showPhone2 = false;
  showEmail1 = false;
  showEmail2 = false;
  regionTranslation;

  constructor(
    private transactionService: TransactionsService,
    private router: Router,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private deviceRegistration: DeviceRegistrationService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private newsService: NewsService,
    private newsNavigate: NewsNavigateService,
    private siilaTranslationService: SiilaTranslationService,
    private productService: ProductService,
    private i18: I18nService,
    private datePipe: DatePipe,
    private localNumberPipe: LocalNumberPipeSpot,
    private renderer: Renderer2
  ) {
    this.breakpointObserver
      .observe(["(max-width: 450px)"])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.currentCode = environment.platformId;
    this.marketImgCard = [
      "assets/img/products-list/indices.png",
      "assets/img/products-list/investors.png",
      "assets/img/products-list/grocs.png"
    ];
    //this.chartOptionsLease = apexObj as ChartOptions;
  }

  ngOnInit() {
    if (this.validateSesion()) {
      this.route.params.subscribe(param => {
        this.id = param.id;
        this.transactionService.getTransactionId(this.id).then(resp => {
          this.transaction = resp;
          this.property = this.transaction.building;
          zip(
            this.propertyService.getPhotosById(this.transaction.building.id),
            this.propertyService.getListingsSpot(this.transaction.building.id),
            this.newsService.getNewsSearch(
              "",
              0,
              5,
              null,
              null,
              null,
              this.transaction.building.id
            ),
            this.transactionService.getSaleCapratesData(
              this.transaction.building.id
            ),
            this.transactionService.getLeaseData(this.transaction.building.id)
          ).subscribe(resp => {
            this.setPhotosType(resp[0]);
            this.listingCards = resp[1] as any;
            this.articles = resp[2] as NewsSearch;
            this.saleCapRate = resp[3];
            this.leaseData = resp[4];
            this.getMarketAnalytics();
            this.validateBrokerList = this.validateBroker();
            this.validateBomaList = this.validateBomaData();
            if (this.transaction?.type == "SALE") {
              this.hideTrxAnalysis = this.isTransactionPrior2021(
                this.transaction.saleDate
              );
              if (this.saleCapRate.length <= 5) {
                this.hideTrxAnalysis = true;
              }
              this.setCharSale(1);
            }

            if (this.transaction?.type == "LEASE") {
              this.hideTrxAnalysis = this.isTransactionPrior2021(
                this.transaction.leaseDate
              );
              this.setCharLease(1);
            }
          });
          this.showDetail = true;
          console.log("transaction", resp);
        });
      });
    } else {
      this.router.navigateByUrl("/");
    }
  }

  getMarketAnalytics() {
    this.productService.getMarketAnalytics().subscribe(products => {
      this.products = products;
      this.marketCardList = products.map((item, index) => {
        return {
          id: item.id,
          background: this.marketImgCard[index],
          src: `${environment.cloudfrontURL}${item.iconPath}/360w/${item.iconFilename}`,
          iconAltTitle: this.findIconAltTitle(item),
          title: item.productShortName,
          text: item.menuItemDescription,
          actionText: item.menuItemActionButtonText
        };
      });
    });
  }

  findIconAltTitle = (item: any) => {
    let card = "imgSEO.cardicons.";
    if (item.id == 101) {
      card += "gross";
    } else if (item.id == 102) {
      card += "index";
    } else {
      card += "investorsurvey";
    }
    return this.siilaTranslationService.getTranslationFromJSONFiles(card);
  };

  setPhotosType(photosList) {
    this.photos = photosList.filter(photo => photo.docType.id == 1001);
    this.registry = photosList.filter(registry => registry.docType.id == 1003);
    this.plan = photosList.filter(plan => plan.docType.id == 1002);
    this.documents = photosList.filter(
      documents => documents.docType.id == 1004
    );
  }

  abbreviatedText(text: string): string {
    const value = text ? text.split(" ") : [];
    if (value.length > 1) {
      const firstLetter = value[0].charAt(0);
      const lastLetter = value[value.length - 1].charAt(0);
      return `${firstLetter}${lastLetter}`;
    } else if (value.length === 1) {
      return value[0].charAt(0);
    } else {
      return text;
    }
  }

  async validateSesion() {
    if (localStorage.getItem("token")) {
      return this.setUser();
    } else {
      let isValidToken = this.deviceRegistration.validateCookie();

      if (isValidToken) {
        let account = await this.accountService.getAccount();
        localStorage.setItem("user", JSON.stringify(account));
        return this.setUser();
      }
    }

    return false;
  }

  setUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.isAdmin = user?.roles?.includes("ROLE_ADMIN");
    if (this.isAdmin || user?.roles?.includes("ROLE_PROPERTIES")) {
      return true;
    }

    return false;
  }

  downloadReport() {
    this.transactionService.downloadReport(this.id).then((resp: any) => {
      if (resp.success) {
        const fileUrl1 = `${environment.apiURL}/transactionPrints/files/${resp.fileName}`;
        const fileUrl2 = `${environment.apiURL}/transactionPrints/files/${resp.excelFileName}`;

        this.downloadFile(fileUrl1, resp.fileName);
        this.downloadFile(fileUrl2, resp.excelFileName);
      }
    });
  }

  downloadFile(url: string, fileName: string) {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  edit() {
    window.open(
      `${environment.serverURL}/#!/transactions/edit/${this.id}`,
      "blank"
    );
  }

  suggestEdit() {}

  openPhotos(type) {
    this.dialogRef = this.dialog.open(ImageGalleryComponent, {
      height: this.isMobile ? "100%" : "auto",
      width: this.isMobile ? "100%" : "44%",
      maxWidth: "none",
      data: this.validatePhotosType(type),
      panelClass: "custom"
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  validatePhotosType(type) {
    switch (type) {
      case 1:
        return this.photos;
        break;
      case 2:
        return this.registry;
        break;
      case 3:
        return this.plan;
        break;
      case 4:
        return this.documents;
        break;
    }
  }

  slickNext(slick) {
    slick.slickNext();
  }

  slickPrev(slick) {
    slick.slickPrev();
  }

  openSpot() {
    window.open(environment.spotURL, "_blank");
  }

  openNews(id: number, title, homeNews = false) {
    if (homeNews) {
      this.router.navigateByUrl("resource");
    } else {
      this.newsNavigate.openNews(
        id,
        title.urlTitle ? title.urlTitle : title.headline,
        this.isMobile,
        "",
        false,
        this.browserLang,
        true,
        null
      );
    }
  }

  validateTrnaslate(text) {
    return this.siilaTranslationService.getTranslation(text);
  }

  goToProperty() {
    window.open(
      `property/${
        this.property.id
      }/lang/${this.siilaTranslationService.getCurrentLanguage()}`
    );
  }

  findBannerImage() {
    var bannerFile;

    if (this.i18.getCurrentLanguage() === "en" || !this.isBR()) {
      bannerFile = "EN_SPOT_BANNER.png";
    } else {
      bannerFile = "PT_SPOT_BANNER.png";
    }

    var fullPath = `../../../../assets/img/property-detail/${bannerFile}`;

    return fullPath;
  }

  findSpotResourceBanner() {
    var bannerFile;

    if (this.isEnglishLang() || !this.isBR()) {
      bannerFile = "EN_REsource_Banner.png";
    } else {
      bannerFile = "PT_REsource_Banner.png";
    }

    var fullPath = `../../../../assets/img/property-detail/${bannerFile}`;
    return fullPath;
  }

  isBR() {
    return this.currentCode && this.currentCode.toLowerCase() === "br";
  }

  openSpotResourceBannerLink() {
    window.open(`resource/lang/${this.i18.getCurrentLanguage()}`);
  }

  isEnglishLang() {
    return this.i18.getCurrentLanguage() === "en";
  }

  openBannerImage() {
    var url;
    if (this.isBR()) {
      url = this.isEnglishLang()
        ? "https://siila.com.br/SPOT/lang/en"
        : "https://siila.com.br/SPOT/lang/pt-br ";
    }
    if (url) {
      window.open(url, this.isMobile ? "_self" : "_blank");
    }
  }

  transactionsPage() {
    window.open(
      `${environment.serverURL}/#!/building/${this.transaction.building.id}/transactions?trxId=${this.transaction.id}`,
      "blank"
    );
  }

  validateBroker() {
    if (
      this.transaction?.broker1Company ||
      this.transaction?.broker1User ||
      this.transaction?.broker1Name ||
      this.transaction?.broker1Email ||
      this.transaction?.broker2User ||
      this.transaction?.broker2Company ||
      this.transaction?.broker2Name ||
      this.transaction?.broker2Email
    ) {
      return true;
    }

    return false;
  }

  validateBomaData() {
    if (
      this.transaction?.bomaLeaseArea ||
      this.transaction?.bomaCondo ||
      this.transaction?.bomaTenantImprovements ||
      this.transaction?.bomaLeasingCommission ||
      this.transaction?.bomaSoldArea ||
      this.transaction?.bomaOccupancyRate
    ) {
      return true;
    } else return false;
  }

  setCharSale(type) {
    this.typeChar = type;

    let maxMin = this.findFieldMinMaxSale(this.saleCapRate);

    this.chartOptions = {
      series: [
        {
          name: this.i18.get("transaction.leaseTransactions"),
          type: "scatter",
          data: [
            {
              x: this.transaction.saleDate,
              y:
                this.typeChar == 1
                  ? this.transaction.salePrice / this.transaction.soldArea
                  : this.transaction.capRate,
              title: this.siilaTranslationService.getTranslation(
                this.transaction.building.title
              ),
              soldArea: this.localNumberPipe.transform(
                this.transaction.soldArea,
                "1.0-0"
              ),
              price: this.localNumberPipe.transform(
                this.transaction.salePrice,
                "1.0-0"
              )
            }
          ]
        },
        {
          name: "Price vs Date",
          type: "scatter",
          data: this.saleCapRate.map(item => ({
            x: item.transactionDate,
            y:
              this.typeChar == 1
                ? item.price / item.soldArea
                : item.capRate > 0
                ? item.capRate
                : 0,
            title: this.siilaTranslationService.getTranslation(item.title),
            soldArea: this.localNumberPipe.transform(item.soldArea, "1.0-0"),
            price: this.localNumberPipe.transform(item.price, "1.0-0"),
            trxId: item.id,
            lang: this.i18.getCurrentLanguage()
          }))
        }
      ],
      chart: {
        type: "scatter",
        height: 350,
        events: {
          mounted: this.addCustomMarkers.bind(this),
          click: function(event, chartContext, opts) {
            let trx =
              chartContext.w.config.series[opts.seriesIndex].data[
                opts.dataPointIndex
              ];
            if (trx.trxId) {
              let url = `/transaction/${trx.trxId}/lang/${trx.lang}`;
              window.open(url, "blank");
            }
          }
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            zoom: false,
            zoomin: false,
            pan: false,
            zoomout: false
          }
        }
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: value => {
            const date = new Date(value);
            const month = date.toLocaleString("en", { month: "short" });
            const year = date.getFullYear();

            return this.browserLang === "pt"
              ? `${monthNamesMap[month]} ${year}`
              : `${month} ${year}`;
          }
        },
        tooltip: {
          formatter: value => {
            const date = new Date(value);
            const localDate = new Date(
              date.getTime() + date.getTimezoneOffset() * 60000
            );
            const month = localDate.toLocaleString("en", { month: "short" });
            const day = localDate.getDate();
            const year = localDate.getFullYear();

            return this.browserLang === "pt"
              ? `${day} ${monthNamesMap[month]} ${year}`
              : `${day} ${month} ${year}`;
          }
        }
      },
      yaxis: {
        title: {
          text:
            this.typeChar == 1
              ? this.i18.get("transaction.salePriceRm")
              : this.i18.get("transaction.capRate") + " (%)"
        },
        labels: {
          formatter: (value: number) => {
            return this.typeChar == 1
              ? this.localNumberPipe.transform(value, "1.0-0")
              : this.localNumberPipe.transform(value, "1.0-1") + "%";
          }
        },
        max: this.typeChar === 2 ? maxMin.overallMax : undefined,
        min: this.typeChar === 2 ? maxMin.overallMin : undefined
      },
      colors: ["#002060"],
      tooltip: {
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const point = w.config.series[seriesIndex].data[dataPointIndex];
          const date = point.x;
          const price =
            this.typeChar == 1
              ? this.localNumberPipe.transform(point.y, "1.0-0")
              : this.localNumberPipe.transform(point.y, "1.0-1");
          return `
            <div class="apexcharts-tooltip-title">${point.title}</div>
            <div style="margin: 10px">
            <div><strong>${this.i18.get(
              "transaction.saleDate"
            )}:</strong> ${this.formatDate(date)}</div>
          ${
            point.price && this.typeChar == 1
              ? `<div><strong>${this.i18.get(
                  "transaction.salePrice"
                )}:</strong> $ ${point.price}</div>`
              : ""
          }
            <div><strong>${
              this.typeChar == 1
                ? point.price && this.typeChar == 1
                  ? this.i18.get("transaction.salePriceRm")
                  : this.i18.get("transaction.salePrice")
                : this.i18.get("transaction.capRate") + " (%)"
            }:</strong> ${
            point.price && this.typeChar == 1 ? "$" : ""
          } ${price}</div>
            <div><strong>${this.i18.get("transaction.soldArea")}:</strong> ${
            point.soldArea
          }</div>
            </div>
          `;
        }
      }
    };
    setTimeout(() => {
      const legend = document.querySelector(".apexcharts-legend");
      this.renderer.setStyle(legend, "display", "none");
    }, 1000);
  }

  setCharLease(type) {
    this.markerCount = 0;
    this.typeChar = type;

    const data = this.leaseData.leaseTransactions.filter(
      column => column.buildingId === this.transaction.building.id
    );

    const buildingTranslation = this.siilaTranslationService.getTranslation(
      this.transaction.building.title
    );
    this.regionTranslation = this.siilaTranslationService.getTranslation(
      this.transaction?.building?.region?.name
    );

    const marketRentSeriesName =
      type === 1
        ? `${buildingTranslation} ${this.i18.get("transaction.marketRent")}`
        : `${this.regionTranslation} ${this.getTabClassLabel(
            this.transaction?.building?.classType?.name
          )} ${this.i18.get("transaction.marketRent")} `;

    const askingRentSeriesName =
      type === 1
        ? `${buildingTranslation}  ${this.i18.get("transaction.askingRent")}`
        : `${this.regionTranslation} ${this.getTabClassLabel(
            this.transaction?.building?.classType?.name
          )} ${this.i18.get("transaction.askingRent")}`;

    const series = [
      {
        name: marketRentSeriesName,
        type: "bar",
        data: this.mapMarketRents("marketRent", "marketRentSameRegion")
      },
      {
        name: askingRentSeriesName,
        type: "bar",
        data: this.mapMarketRents("askingRent", "askingRentSameRegion")
      },
      {
        name: `${this.i18.get(
          "transaction.leaseTransactions"
        )} ${buildingTranslation}`,
        type: "scatter",
        data: [
          {
            x: this.setQuarter(this.transaction.leaseDate),
            y: (
              this.transaction.monthlyRent / this.transaction.leasedArea
            ).toFixed(2),
            title: buildingTranslation,
            leasedArea: this.transaction.leasedArea,
            tenant: this.siilaTranslationService.getTranslation(
              this.transaction.tenants
            ),
            leaseDate: this.transaction.leaseDate
          }
        ]
      }
    ];

    let filledSeries = this.fillMissingData(series);
    filledSeries = this.sortDataByDate(filledSeries);

    if (filledSeries[0].data.length <= 2) {
      filledSeries[0].data.push({
        x: "",
        y: null
      });
      filledSeries[0].data.push({
        x: "",
        y: null
      });
    }

    console.log("mire", filledSeries);
    let maxMin = this.findFieldMinMax(this.leaseData.marketRents);

    this.chartOptionsLease = {
      series: filledSeries,
      chart: {
        type: "scatter",
        height: 350,
        events: {
          mounted: this.addCustomMarkers.bind(this)
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            zoom: false,
            zoomin: false,
            pan: false,
            zoomout: false
          }
        }
      },
      markers: {
        size: 20
      },
      xaxis: {
        type: "datetime",
        title: {
          text: this.i18.get("transaction.date")
        }
      },
      yaxis: {
        title: {
          text: this.i18.get("transaction.rent")
        },
        labels: {
          formatter: (value: number) => Math.round(value).toString()
        },
        max: maxMin.overallMax,
        min: 0
      },
      colors:
        type === 1
          ? ["#002060", "#457282", "#f90"]
          : ["#7f7f7f", "#2A555D", "#f90"],
      tooltip: {
        custom: this.customTooltip.bind(this)
      }
    };

    const askingRentData = series[1].data.filter(item => item.y != null);
    console.log(askingRentData);

    if (askingRentData.length == 0) {
      setTimeout(() => {
        const legend = document.querySelector(
          ".apexcharts-legend .apexcharts-legend-series[rel='2']"
        );
        this.renderer.setStyle(legend, "display", "none");
      }, 1000);
    }
  }

  private mapMarketRents(primaryField: string, secondaryField: string) {
    return this.leaseData.marketRents.map(column => ({
      x: this.setQuarter(column.dateSurveyed),
      y:
        this.typeChar === 1
          ? this.getValueOrZero(column[primaryField])
          : this.getValueOrZero(column[secondaryField]),
      title:
        this.typeChar === 1
          ? this.i18.get(`transaction.${primaryField}`)
          : this.i18.get(`transaction.${secondaryField}`)
    }));
  }

  private getValueOrZero(value: number | null): number {
    return value ? parseFloat(value.toFixed(2)) : null;
  }

  private customTooltip({ seriesIndex, dataPointIndex, w }, regionTranslation) {
    let point = null;
    let point2 = null;
    let price2 = null;

    if (seriesIndex === 2) {
      point = w.config.series[seriesIndex].data[dataPointIndex];
    } else {
      point =
        w.config.series[seriesIndex === 0 ? seriesIndex : seriesIndex - 1].data[
          dataPointIndex
        ];
      point2 =
        w.config.series[seriesIndex === 1 ? seriesIndex : seriesIndex + 1].data[
          dataPointIndex
        ];
      price2 = parseFloat(point2.y).toLocaleString();
    }

    const date = point.x;
    const price = parseFloat(point.y).toLocaleString();

    return `
        <div class="apexcharts-tooltip-title">${
          seriesIndex === 2
            ? this.siilaTranslationService.getTranslation(
                this.transaction.building.title
              )
            : date
        }</div>
        <div style="margin: 10px">
          ${
            point.tenant
              ? `<div><strong>${this.i18.get("transaction.tenant")}:</strong> ${
                  point.tenant
                }</div>`
              : ""
          }
          <div><strong>${
            seriesIndex === 2
              ? this.i18.get("transaction.date")
              : this.getSeriesTitle(seriesIndex)
          }:</strong> ${
      seriesIndex === 2
        ? this.formatDate(
            point.leaseDate
              ? point.leaseDate
              : this.browserLang === "pt"
              ? this.datePipe.transform(date, "dd/MM/yyyy")
              : this.datePipe.transform(date, "MM/dd/yyyy")
          )
        : this.localNumberPipe.transform(parseFloat(price), "1.0-0")
    }</div>
          <div><strong>${
            seriesIndex === 2
              ? this.i18.get("transaction.monthlyRentPerArea") + ":"
              : price2 != "NaN"
              ? this.getAskingRentTitle(seriesIndex) + ":"
              : ""
          }</strong> ${
      seriesIndex === 2
        ? this.localNumberPipe.transform(parseFloat(price), "1.0-0")
        : price2 != "NaN"
        ? this.localNumberPipe.transform(parseFloat(price2), "1.0-0")
        : ""
    }</div>
          ${
            point.leasedArea
              ? `<div><strong>${this.i18.get(
                  "transaction.leasedAreaChart"
                )}:</strong> ${this.localNumberPipe.transform(
                  point.leasedArea,
                  "1.0-0"
                )}</div>`
              : ""
          }
        </div>
      `;
  }

  private getSeriesTitle(seriesIndex: number): string {
    return this.typeChar === 1
      ? this.siilaTranslationService.getTranslation(
          this.transaction.building.title
        ) +
          " " +
          this.i18.get("transaction.marketRent")
      : `${this.regionTranslation} ${this.getTabClassLabel(
          this.transaction?.building?.classType?.name
        )} ${this.i18.get("transaction.marketRent")}`;
  }

  private getAskingRentTitle(seriesIndex: number): string {
    return this.typeChar === 1
      ? this.siilaTranslationService.getTranslation(
          this.transaction.building.title
        ) +
          " " +
          this.i18.get("transaction.askingRent")
      : `${this.regionTranslation} ${this.getTabClassLabel(
          this.transaction?.building?.classType?.name
        )} ${this.i18.get("transaction.askingRent")}`;
  }

  private formatDate(date: string | Date) {
    return this.browserLang === "pt"
      ? this.datePipe.transform(date, "dd/MM/yyyy")
      : this.datePipe.transform(date, "MM/dd/yyyy");
  }

  quarterToDate(quarter: string): Date {
    const [q, year] = quarter.split(this.browserLang === "pt" ? " T " : " Q ");
    const quarterNumber = parseInt(q);
    const month = (quarterNumber - 1) * 3;
    return new Date(parseInt(year), month);
  }

  sortDataByDate(series: any[]): any[] {
    return series.map(s => ({
      ...s,
      data: s.data.sort((a, b) => {
        const dateA = this.quarterToDate(a.x);
        const dateB = this.quarterToDate(b.x);
        return dateA.getTime() - dateB.getTime();
      })
    }));
  }

  fillMissingData(series) {
    let allDates: { [key: string]: number } = {};

    series.forEach(s => {
      s.data.forEach(point => {
        if (point.x in allDates) {
          allDates[point.x]++;
        } else {
          allDates[point.x] = 1;
        }
      });
    });

    let uniqueDates = Object.keys(allDates).sort((a, b) => {
      const [qA, yA] = a
        .split(this.browserLang === "pt" ? " T " : " Q ")
        .map(part => parseInt(part, 10));
      const [qB, yB] = b
        .split(this.browserLang === "pt" ? " T " : " Q ")
        .map(part => parseInt(part, 10));
      return yB - yA || qB - qA;
    });

    let filledSeries = series.map(s => {
      let filledData = uniqueDates.map(date => {
        let foundItem = s.data.find(item => item.x === date);
        return foundItem || { x: date, y: null, title: s.name };
      });

      return { ...s, data: filledData };
    });

    return filledSeries;
  }

  addCustomMarkers() {
    const chartContainer = this.chartElement.nativeElement;

    const existingMarkers = chartContainer.querySelectorAll(
      ".custom-marker-img"
    );
    existingMarkers.forEach(marker => marker.remove());

    const scatterPoints =
      this.transaction?.type == "SALE"
        ? chartContainer.querySelectorAll(
            ".apexcharts-scatter-series .apexcharts-series[rel='1'] .apexcharts-marker"
          )
        : chartContainer.querySelectorAll(
            ".apexcharts-series-scatter .apexcharts-marker"
          );

    scatterPoints.forEach(point => {
      point.classList.add("custom-marker-size");

      const img = document.createElement("img");
      img.src = "assets/icons/point-stars.png";
      img.className = "custom-marker-img";
      img.style.width = "40px";
      img.style.height = "40px";
      img.style.position = "absolute";
      img.style.pointerEvents = "none";

      const rect = point.getBoundingClientRect();

      const chartRect = chartContainer.getBoundingClientRect();

      const offsetX = 14;
      const offsetY = 14;
      img.style.left =
        rect.left - chartRect.left + rect.width / 2 - offsetX + 2 - 8 + "px";
      img.style.top =
        rect.top - chartRect.top + rect.height / 2 - offsetY - 6 + "px";

      chartContainer.appendChild(img);
    });
  }

  isTransactionPrior2021(date) {
    let input = new Date(date);

    if (
      this.transaction?.type == "LEASE" &&
      this.leaseData &&
      this.leaseData.marketRents.length == 0
    ) {
      return true;
    }

    if (
      (input.getFullYear() <= 2014 && this.transaction?.type == "SALE") ||
      (input.getFullYear() <= 2020 && this.transaction?.type == "LEASE") ||
      (this.transaction.building.industrialStatus != null &&
        this.transaction.building.industrialStatus.id != 1001 &&
        this.transaction.building.industrialStatus.id != 1) ||
      this.transaction.building.classType == null
    ) {
      return true;
    } else {
      return false;
    }
  }

  setQuarter(date) {
    let input = new Date(date);
    return `${Math.floor((input.getMonth() + 3) / 3)} ${
      this.browserLang === "pt" ? "T" : "Q"
    } ${this.datePipe.transform(input, "yyyy")}`;
  }

  headerImageNews(item: string): string {
    const url = item.replace("***", "640w");
    return `${environment.cloudfrontURL}${url}`;
  }

  findFieldMinMax(
    rentDataArray: any[]
  ): { overallMin: number; overallMax: number } {
    let minAskingRent = Infinity;
    let maxAskingRent = -Infinity;
    let minAskingRentSameRegion = Infinity;
    let maxAskingRentSameRegion = -Infinity;
    let minMarketRent = Infinity;
    let maxMarketRent = -Infinity;
    let minMarketRentSameRegion = Infinity;
    let maxMarketRentSameRegion = -Infinity;

    rentDataArray.forEach(data => {
      const askingRent = data.askingRent !== null ? data.askingRent : 0;
      const askingRentSameRegion =
        data.askingRentSameRegion !== null ? data.askingRentSameRegion : 0;
      const marketRent = data.marketRent !== null ? data.marketRent : 0;
      const marketRentSameRegion =
        data.marketRentSameRegion !== null ? data.marketRentSameRegion : 0;

      minAskingRent = Math.min(minAskingRent, askingRent);
      maxAskingRent = Math.max(maxAskingRent, askingRent);

      minAskingRentSameRegion = Math.min(
        minAskingRentSameRegion,
        askingRentSameRegion
      );
      maxAskingRentSameRegion = Math.max(
        maxAskingRentSameRegion,
        askingRentSameRegion
      );

      minMarketRent = Math.min(minMarketRent, marketRent);
      maxMarketRent = Math.max(maxMarketRent, marketRent);

      minMarketRentSameRegion = Math.min(
        minMarketRentSameRegion,
        marketRentSameRegion
      );
      maxMarketRentSameRegion = Math.max(
        maxMarketRentSameRegion,
        marketRentSameRegion
      );
    });

    const minValues = [
      minAskingRent,
      minAskingRentSameRegion,
      minMarketRent,
      minMarketRentSameRegion,
      this.transaction.monthlyRentPerArea > 0
        ? this.transaction.monthlyRentPerArea
        : minMarketRentSameRegion
    ];

    const positiveValues = minValues.filter(value => value > 0);
    const overallMin = Math.min(...positiveValues) * 0.98;
    const overallMax =
      Math.max(
        maxAskingRent,
        maxAskingRentSameRegion,
        maxMarketRent,
        maxMarketRentSameRegion,
        this.transaction.monthlyRentPerArea > 0
          ? this.transaction.monthlyRentPerArea
          : maxMarketRentSameRegion
      ) * 1.05;

    return { overallMin, overallMax };
  }

  findFieldMinMaxSale(
    capRateDataArray: any[]
  ): { overallMin: number; overallMax: number } {
    let minCapRate = Infinity;
    let maxCapRate = -Infinity;

    capRateDataArray.forEach(data => {
      const capRate = data.capRate !== null ? data.capRate : 0;

      if (capRate > 0) {
        minCapRate = Math.min(minCapRate, capRate);
        maxCapRate = Math.max(maxCapRate, capRate);
      }
    });

    if (this.transaction.capRate && this.transaction.capRate > 0) {
      minCapRate = Math.min(minCapRate, this.transaction.capRate);
      maxCapRate = Math.max(maxCapRate, this.transaction.capRate);
    }

    const overallMin = minCapRate * 0.9;
    const overallMax = maxCapRate * 1.06;

    return { overallMin, overallMax };
  }

  getTabClassLabel(classType: string, state = false): string {
    if (state) {
      if (classType === "A+" || classType === "A") {
        return "A+ & A";
      } else if (classType === "B" || classType === "C") {
        return "B & C";
      }
    }
    return classType;
  }
}
